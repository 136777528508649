import {Model} from '@vuex-orm/core';

export default class Widmung extends Model {
  static entity = 'widmung';
  static fields() {
    return {
      id: this.number(null),
      kuerzel: this.string(''),
      bezeichnung: this.string('')
    };
  }
}
