<template>
  <v-overlay
    justify="center"
    align="center"
    :value="isLoading"
    dark
    z-index="999999"
    class="text-h3"
  >
    <div
      class="text-center ma-12"
    >
      <v-progress-circular
        indeterminate
        rotate="0"
        size="100"
        width="14"
        color="#FFF"
      />
    </div>
    {{ message }}
  </v-overlay>
</template>

<script>
export default {
  name: 'LoadingScreen',
  props: {
    isLoading: {
      type: Boolean,
      default: () => false
    },
    message: {
      type: String,
      default: () => ''
    }
  }
};
</script>

