var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"content pa-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"spaceAbove",class:{
      'mx-16': _vm.$vuetify.breakpoint.mdAndUp,
      'mx-6': _vm.$vuetify.breakpoint.smAndDown,
    },attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"mx-0",attrs:{"lg":"3","sm":"6","cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{staticClass:"transparentBack",attrs:{"elevation":hover ? 10 : 6,"href":_vm.foerderungen_1_link,"target":"_blank"}},[_c('v-img',{staticClass:"fotoHolder",attrs:{"width":"100%","height":"140","src":"/img/foerderung-tourismus.jpg","position":"center center"}}),_c('v-sheet',{staticClass:"pa-4 backgroundGradient",class:{'sameHeight' : !_vm.admin}},[(_vm.admin)?_c('v-text-field',{staticClass:"py-2",attrs:{"label":"Titel 1"},nativeOn:{"click":function($event){$event.preventDefault();}},model:{value:(_vm.foerderungen_1_title),callback:function ($$v) {_vm.foerderungen_1_title=$$v},expression:"foerderungen_1_title"}}):_c('v-card-title',{staticClass:"py-2"},[_vm._v(" "+_vm._s(_vm.foerderungen_1_title)+" ")]),(_vm.admin)?_c('v-text-field',{staticClass:"py-2",attrs:{"label":"Link 1 URL"},nativeOn:{"click":function($event){$event.preventDefault();}},model:{value:(_vm.foerderungen_1_link),callback:function ($$v) {_vm.foerderungen_1_link=$$v},expression:"foerderungen_1_link"}}):_vm._e(),(_vm.admin)?_c('div',{staticClass:"mb-12"},[_c('tinyMCE',{attrs:{"init":{
                  content_css: false,
                  skin: false,
                  setup: function (ed) {
                    ed.on('init', function () {
                      this.getDoc().body.style.fontSize = '12';
                      this.getDoc().body.style.fontFamily = 'Roboto, sans-serif';
                    });
                  },
                  statusbar: false,
                  height: 300,
                  menubar: false,
                  block_formats: 'Text=p; Überschrift 1=h1; Überschrift 2=h2',
                  plugins: [
                    'lists',
                    'link',
                    'paste'
                  ],
                  paste_as_text: true,
                  language_url: '/langs/de.js',
                  language: 'de',
                  default_link_target: '_blank',
                  toolbar:
                    'undo redo | bold italic underline | link | bullist numlist outdent indent | formatselect | removeformat'
                }},model:{value:(_vm.foerderungen_1_text),callback:function ($$v) {_vm.foerderungen_1_text=$$v},expression:"foerderungen_1_text"}})],1):_c('v-card-text',[_c('span',{class:{
                  barrierefrei: _vm.$route.query.barrierefrei
                },domProps:{"innerHTML":_vm._s(_vm.foerderungen_1_text)}})])],1)],1)]}}])})],1),_c('v-col',{staticClass:"mx-0",attrs:{"lg":"3","sm":"6","cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{staticClass:"transparentBack",attrs:{"elevation":hover ? 10 : 6,"href":_vm.foerderungen_2_link,"target":"_blank"}},[_c('v-img',{staticClass:"fotoHolder",attrs:{"width":"100%","height":"140","src":"/img/foerderung-gewerbeindustrie.jpg","position":"center center"}}),_c('v-sheet',{staticClass:"pa-4 backgroundGradient",class:{'sameHeight' : !_vm.admin}},[(_vm.admin)?_c('v-text-field',{staticClass:"py-2",attrs:{"label":"Titel 2"},nativeOn:{"click":function($event){$event.preventDefault();}},model:{value:(_vm.foerderungen_2_title),callback:function ($$v) {_vm.foerderungen_2_title=$$v},expression:"foerderungen_2_title"}}):_c('v-card-title',{staticClass:"py-2"},[_vm._v(" "+_vm._s(_vm.foerderungen_2_title)+" ")]),(_vm.admin)?_c('v-text-field',{staticClass:"py-2",attrs:{"label":"Link 2 URL"},nativeOn:{"click":function($event){$event.preventDefault();}},model:{value:(_vm.foerderungen_2_link),callback:function ($$v) {_vm.foerderungen_2_link=$$v},expression:"foerderungen_2_link"}}):_vm._e(),(_vm.admin)?_c('div',{staticClass:"mb-12"},[_c('tinyMCE',{attrs:{"init":{
                  content_css: false,
                  skin: false,
                  setup: function (ed) {
                    ed.on('init', function () {
                      this.getDoc().body.style.fontSize = '12';
                      this.getDoc().body.style.fontFamily = 'Roboto, sans-serif';
                    });
                  },
                  statusbar: false,
                  height: 300,
                  menubar: false,
                  block_formats: 'Text=p; Überschrift 1=h1; Überschrift 2=h2',
                  plugins: [
                    'lists',
                    'link',
                    'paste'
                  ],
                  paste_as_text: true,
                  language_url: '/langs/de.js',
                  language: 'de',
                  default_link_target: '_blank',
                  toolbar:
                    'undo redo | bold italic underline | link | bullist numlist outdent indent | formatselect | removeformat'
                }},model:{value:(_vm.foerderungen_2_text),callback:function ($$v) {_vm.foerderungen_2_text=$$v},expression:"foerderungen_2_text"}})],1):_c('v-card-text',[_c('span',{class:{
                  barrierefrei: _vm.$route.query.barrierefrei
                },domProps:{"innerHTML":_vm._s(_vm.foerderungen_2_text)}})])],1)],1)]}}])})],1),_c('v-col',{staticClass:"mx-0",attrs:{"lg":"3","sm":"6","cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{staticClass:"transparentBack",attrs:{"elevation":hover ? 10 : 6,"href":_vm.foerderungen_3_link,"target":"_blank"}},[_c('v-img',{staticClass:"fotoHolder",attrs:{"width":"100%","height":"140","src":"/img/foerderung-innovation.jpg","position":"center center"}}),_c('v-sheet',{staticClass:"pa-4 backgroundGradient",class:{'sameHeight' : !_vm.admin}},[(_vm.admin)?_c('v-text-field',{staticClass:"py-2",attrs:{"label":"Titel 3"},nativeOn:{"click":function($event){$event.preventDefault();}},model:{value:(_vm.foerderungen_3_title),callback:function ($$v) {_vm.foerderungen_3_title=$$v},expression:"foerderungen_3_title"}}):_c('v-card-title',{staticClass:"py-2"},[_vm._v(" "+_vm._s(_vm.foerderungen_3_title)+" ")]),(_vm.admin)?_c('v-text-field',{staticClass:"py-2",attrs:{"label":"Link 3 URL"},nativeOn:{"click":function($event){$event.preventDefault();}},model:{value:(_vm.foerderungen_3_link),callback:function ($$v) {_vm.foerderungen_3_link=$$v},expression:"foerderungen_3_link"}}):_vm._e(),(_vm.admin)?_c('div',{staticClass:"mb-12"},[_c('tinyMCE',{attrs:{"init":{
                  content_css: false,
                  skin: false,
                  setup: function (ed) {
                    ed.on('init', function () {
                      this.getDoc().body.style.fontSize = '12';
                      this.getDoc().body.style.fontFamily = 'Roboto, sans-serif';
                    });
                  },
                  statusbar: false,
                  height: 300,
                  menubar: false,
                  block_formats: 'Text=p; Überschrift 1=h1; Überschrift 2=h2',
                  plugins: [
                    'lists',
                    'link',
                    'paste'
                  ],
                  paste_as_text: true,
                  language_url: '/langs/de.js',
                  language: 'de',
                  default_link_target: '_blank',
                  toolbar:
                    'undo redo | bold italic underline | link | bullist numlist outdent indent | formatselect | removeformat'
                }},model:{value:(_vm.foerderungen_3_text),callback:function ($$v) {_vm.foerderungen_3_text=$$v},expression:"foerderungen_3_text"}})],1):_c('v-card-text',[_c('span',{class:{
                  barrierefrei: _vm.$route.query.barrierefrei
                },domProps:{"innerHTML":_vm._s(_vm.foerderungen_3_text)}})])],1)],1)]}}])})],1),_c('v-col',{staticClass:"mx-0",attrs:{"lg":"3","sm":"6","cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{staticClass:"transparentBack",attrs:{"elevation":hover ? 10 : 6,"href":_vm.foerderungen_4_link,"target":"_blank"}},[_c('v-img',{staticClass:"fotoHolder",attrs:{"width":"100%","height":"140","src":"/img/foerderung-finanzierungen.jpg","position":"center center"}}),_c('v-sheet',{staticClass:"pa-4 backgroundGradient",class:{'sameHeight' : !_vm.admin}},[(_vm.admin)?_c('v-text-field',{staticClass:"py-2",attrs:{"label":"Titel 4"},nativeOn:{"click":function($event){$event.preventDefault();}},model:{value:(_vm.foerderungen_4_title),callback:function ($$v) {_vm.foerderungen_4_title=$$v},expression:"foerderungen_4_title"}}):_c('v-card-title',{staticClass:"py-2"},[_vm._v(" "+_vm._s(_vm.foerderungen_4_title)+" ")]),(_vm.admin)?_c('v-text-field',{staticClass:"py-2",attrs:{"label":"Link 4 URL"},nativeOn:{"click":function($event){$event.preventDefault();}},model:{value:(_vm.foerderungen_4_link),callback:function ($$v) {_vm.foerderungen_4_link=$$v},expression:"foerderungen_4_link"}}):_vm._e(),(_vm.admin)?_c('div',{staticClass:"mb-12"},[_c('tinyMCE',{attrs:{"init":{
                  content_css: false,
                  skin: false,
                  setup: function (ed) {
                    ed.on('init', function () {
                      this.getDoc().body.style.fontSize = '12';
                      this.getDoc().body.style.fontFamily = 'Roboto, sans-serif';
                    });
                  },
                  statusbar: false,
                  height: 300,
                  menubar: false,
                  block_formats: 'Text=p; Überschrift 1=h1; Überschrift 2=h2',
                  plugins: [
                    'lists',
                    'link',
                    'paste'
                  ],
                  paste_as_text: true,
                  language_url: '/langs/de.js',
                  language: 'de',
                  default_link_target: '_blank',
                  toolbar:
                    'undo redo | bold italic underline | link | bullist numlist outdent indent | formatselect | removeformat'
                }},model:{value:(_vm.foerderungen_4_text),callback:function ($$v) {_vm.foerderungen_4_text=$$v},expression:"foerderungen_4_text"}})],1):_c('v-card-text',[_c('span',{class:{
                  barrierefrei: _vm.$route.query.barrierefrei
                },domProps:{"innerHTML":_vm._s(_vm.foerderungen_4_text)}})])],1)],1)]}}])})],1)],1),_c('div',{staticStyle:{"width":"5px","height":"150px"}}),(_vm.admin)?_c('TheTextbausteinConfirm'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }