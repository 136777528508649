import {Model} from '@vuex-orm/core';

export default class Objekt extends Model {
  static entity = 'objekt';
  static fields() {
    return {
      createdAt: this.attr(undefined),
      updatedAt: this.attr(undefined),
      id: this.attr(null),
      objektnummer: this.number(0),
      typ: this.string('Grundstück'),
      hauptname: this.string(''),
      zusatzname: this.string(''),
      strasse: this.string(''),
      hausnummer: this.string(''),
      plz: this.number(0),
      ort: this.string(''),
      geo_lat: this.number(0),
      geo_lon: this.number(0),
      geo_poly: this.string(''),
      verkehrsanbindung: this.string(''),
      infrastruktur: this.string(''),
      link: this.string(''),
      detailbeschreibung: this.string(''),
      flaeche: this.number(0),
      teilbar: this.number(0),
      kleinsteflaeche: this.number(0),
      grundstücksnummer: this.string(''),
      gebaeudetyp: this.string(''),
      hallenhoehe: this.number(0),
      anmerkungen: this.string(''),
      bilder: this.attr([]),
      dateien: this.attr([]),
      lageplaene: this.attr([]),
      miete: this.boolean(false),
      mietpreis: this.number(0),
      mietpreis_quadratmeter: this.number(0),
      kauf: this.boolean(false),
      kaufpreis: this.number(0),
      kaufpreis_quadratmeter: this.number(0),
      eigentuemer_titel: this.string(''),
      eigentuemer_vorname: this.string(''),
      eigentuemer_nachname: this.string(''),
      eigentuemer_firma: this.string(''),
      eigentuemer_adresse: this.string(''),
      eigentuemer_hausnr: this.string(''),
      eigentuemer_plz: this.number(0),
      eigentuemer_ort: this.string(''),
      eigentuemer_telefonnummer: this.string(''),
      eigentuemer_email: this.string(''),
      eigentuemer_mobil: this.string(''),
      eigentuemer_fax: this.string(''),
      eigentuemer_anmerkung: this.string(''),
      intern_anmerkung: this.string(''),
      intern_revisionshistorie: this.string(''),
      intern_leerstand: this.string(''),
      gehoert_zu: this.string(''),
      status: this.number(0),
      ranking: this.number(1),
      widmung: this.number(0),
      widmung2: this.number(0),
      widmung3: this.number(0),
      bezirk: this.number(0),
      katastralgemeinde: this.number(0),
      politischegemeinde: this.number(0)
    };
  }
}
