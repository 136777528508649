import Vue from 'vue';
import Vuex from 'vuex';
import VuexORM from '@vuex-orm/core';
import database from '@/database';
import VuexORMAxios from '@vuex-orm/plugin-axios';
import axios from 'axios';

Vue.use(Vuex);
VuexORM.use(VuexORMAxios, {axios});

export default new Vuex.Store({
  plugins: [VuexORM.install(database)],
  state: {
    baseLoaded: false,
    isDirty: false,
    userdata: null,
    lastId: null
  },
  mutations: {
    allLoaded(state) {
      state.baseLoaded = true;
    },
    isDirty: (state, dirty) => {
      state.isDirty = dirty;
    },
    userdata: (state, userdata) => {
      state.userdata = userdata;
    },
    lastId: (state, lastId) => {
      state.lastId = lastId;
    }

  },
  actions: {
    isDirty: ({state, commit}, dirty) => {
      commit('isDirty', dirty);
    },
    userdata: ({state, commit}, userdata) => {
      commit('userdata', userdata);
    },
    lastId: ({state, commit}, lastId) => {
      commit('lastId', lastId);
    }
  },
  getters: {
    baseLoaded: state => state.baseLoaded
  }
});
