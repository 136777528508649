import Vue from 'vue';
import VueRouter from 'vue-router';
import About from '@/views/About.vue';
import Privacy from '@/views/Privacy.vue';
import Businessparks from '@/views/user/Businessparks.vue';
import Technologiezentren from '@/views/user/Technologiezentren.vue';
import Foerderungen from '@/views/user/Foerderungen.vue';
import Kontakt from '@/views/Kontakt.vue';
import Login from '@/views/Login.vue';
import Home from '@/views/Home.vue';
import {v4 as uuidv4} from 'uuid';
import store from '@/store';
import Axios from 'axios';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    props: true,
    meta: {
      title: 'Standortsuche Burgenland - Startseite',
      requiresAuth: false // set to real boolean for header items
    }
  }, {
    path: '/ueber',
    name: 'ueber',
    component: About,
    props: true,
    meta: {
      title: 'Standortsuche Burgenland - Über',
      requiresAuth: false // set to real boolean for header items
    }
  }, {
    path: '/datenschutz',
    name: 'datenschutz',
    component: Privacy,
    props: true,
    meta: {
      title: 'Standortsuche Burgenland - Datenschutz',
      requiresAuth: false // set to real boolean for header items
    }
  }, {
    path: '/businessparks',
    name: 'businessparks',
    component: Businessparks,
    props: true,
    meta: {
      title: 'Standortsuche Burgenland - Businessparks',
      requiresAuth: false // set to real boolean for header items
    }
  }, {
    path: '/technologiezentren',
    name: 'technologiezentren',
    component: Technologiezentren,
    props: true,
    meta: {
      title: 'Standortsuche Burgenland - Technologiezentren',
      requiresAuth: false // set to real boolean for header items
    }
  }, {
    path: '/foerderungen',
    name: 'foerderungen',
    component: Foerderungen,
    props: true,
    meta: {
      title: 'Standortsuche Burgenland - Förderungen',
      requiresAuth: false // set to real boolean for header items
    }
  }, {
    path: '/kontakt',
    name: 'kontakt',
    component: Kontakt,
    props: true,
    meta: {
      title: 'Standortsuche Burgenland - Kontakt',
      requiresAuth: false // set to real boolean for header items
    }
  }, {
    path: '/admin',
    redirect: '/admin/liste'
  },
  {
    path: '/admin/liste',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/Browse.vue'),
    meta: {
      title: 'Standortsuche Burgenland - Admin Liste',
      requiresAuth: true
    }
  },
  {
    path: '/admin/objekt/neu',
    redirect: to => `/admin/objekt/${uuidv4()}`
  },
  {
    path: '/admin/objekt/:objektId',
    name: 'admin-objekt',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/Editor.vue'),
    props: true,
    meta: {
      title: 'Standortsuche Burgenland - Objekteditor',
      requiresAuth: true
    }
  },
  {
    path: '/textbausteine',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/Textbausteine.vue'),
    meta: {
      title: 'Standortsuche Burgenland - Textbausteine',
      requiresAuth: true
    }
  },
  {
    path: '/leerstand',
    name: 'leerstand',
    component: () => import(/* webpackChunkName: "user" */ '@/views/user/TheUser.vue'),
    meta: {
      title: 'Standortsuche Burgenland - Leerstandsuche',
      requiresAuth: false // set to real boolean for header items
    }
  },
  {
    path: '/leerstand/:objektId',
    component: () => import(/* webpackChunkName: "user" */ '@/views/user/TheUser.vue'),
    props: true,
    meta: {
      title: 'Standortsuche Burgenland - Standortsuche',
      requiresAuth: false // set to real boolean for header items
    }
  },
  {
    path: '/standortsuche',
    name: 'standortsuche',
    component: () => import(/* webpackChunkName: "user" */ '@/views/user/TheUser.vue'),
    meta: {
      title: 'Standortsuche Burgenland - Standortsuche',
      requiresAuth: false // set to real boolean for header items
    }
  },
  {
    path: '/standortsuche/:objektId',
    component: () => import(/* webpackChunkName: "user" */ '@/views/user/TheUser.vue'),
    props: true,
    meta: {
      title: 'Standortsuche Burgenland - Standortsuche',
      requiresAuth: false // set to real boolean for header items
    }
  },
  {
    path: '/login',
    component: Login,
    meta: {
      title: 'Standortsuche Burgenland - Login'
    }
  },
  {
    path: '/logout',
    name: 'logout',
    component: {
      async beforeRouteEnter(to, from, next) {
        try {
          await Axios.get('/api/logout');
          next('/login');
        } catch (e) {
          next('/admin/liste');
        }
      }
    }
  },
  {
    path: '*',
    redirect: '/'
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

function hasQueryParams(route) {
  return !!Object.keys(route.query).length;
}

// keep queries (especially "barrierefrei") when switching in between routes
router.beforeEach((to, from, next) => {
  if (!hasQueryParams(to) && hasQueryParams(from)) {
    if (to.name !== from.name) {
      next({name: to.name, query: from.query});
    } else {
      next({name: to.name, query: from.query});
    }
  } else {
    next();
  }
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const errNext = {path: '/login', query: {next: location.href}};
    if (!store.state.userdata) {
      try {
        const response = await Axios.get('/api/userdata');
        store.dispatch('userdata', response.data);
      } catch (err) {
        //
      }
    }
    document.title = to.meta.title;
    next(store.state.userdata.userlevel < 100 ? undefined : errNext);
  } else {
    document.title = to.meta.title;
    next();
  }
});

export default router;
