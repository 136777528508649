import TileLayer from 'ol/layer/Tile';
import XYZSource from 'ol/source/XYZ';
import {createXYZ} from 'ol/tilegrid';
import TileGrid from 'ol/tilegrid/TileGrid';
import {Vector as VectorSource} from 'ol/source';
import {Group, Vector as VectorLayer} from 'ol/layer';
import {GeoJSON} from 'ol/format';
import {Stroke, Style} from 'ol/style';
import {switchResolution, normalIconResolution} from './constants';
import objektSource from '@/main/objektSource';
import {objektStyle} from '@/main/style';

const bmapAttribution = '<a href="http://www.basemap.at">basemap.at</a> &copy; <a href="http://creativecommons.org/licenses/by/3.0/at/">CC BY 3.0 AT</a>';
const bmapExtent = [977650, 5838030, 1913530, 6281290];

const bmapTilegrid = new TileGrid({
  extent: bmapExtent,
  origin: [-20037508.3428, 20037508.3428],
  resolutions: createXYZ({
    maxZoom: 18
  }).getResolutions()
});

/**
 * @returns {import("ol/layer/Group").default}
 */
export function createOrthoGroup() {
  const orthoLayer = new TileLayer({
    source: new XYZSource({
      attributions: bmapAttribution,
      crossOrigin: 'anonymous',
      tileGrid: bmapTilegrid,
      url: 'https://mapsneu.wien.gv.at/basemap/bmaporthofoto30cm/normal/google3857/{z}/{y}/{x}.jpeg'
    })
  });

  const orthoOverLays = new TileLayer({
    source: new XYZSource({
      attributions: bmapAttribution,
      crossOrigin: 'anonymous',
      tileGrid: bmapTilegrid,
      url: 'https://mapsneu.wien.gv.at/basemap/bmapoverlay/normal/google3857/{z}/{y}/{x}.png'
    })
  });

  return (new Group({
    maxResolution: switchResolution,
    layers: [
      orthoLayer,
      orthoOverLays
    ]
  }));
}

/**
 * @returns {import("ol/source/XYZ").default}
 */
export function createBmapSource() {
  return new XYZSource({
    attributions: bmapAttribution,
    crossOrigin: 'anonymous',
    tileGrid: bmapTilegrid,
    url: 'https://mapsneu.wien.gv.at/basemap/geolandbasemap/normal/google3857/{z}/{y}/{x}.png'
  });
}

/**
 * create a stamen background source
 * @returns {import("ol/source/XYZ.js").default}
 */
export function createStamenSource() {
  return new XYZSource({
    url: 'https://assets.w3geo.at/tiles/stamentoner/{z}/{x}/{y}.png',
    maxZoom: 13,
    crossOrigin: 'anonymous'
  });
}

/**
 * returns all needed Layers.
 * each single layer has a unique name:
 * [bmap, orthofoto]
 * @returns {Array<import("ol/layer/Layer").default | import("ol/layer/Group").default>}
 */
export function createAllLayers() {
  const stamenToner = new TileLayer({
    source: createStamenSource()
  });
  stamenToner.set('name', 'stamen');

  const bmapLayer = new TileLayer({
    minResolution: switchResolution,
    maxResolution: normalIconResolution,
    source: createBmapSource()
  });
  bmapLayer.set('name', 'bmap');

  const bmapGrauLayer = new TileLayer({
    minResolution: normalIconResolution,
    source: new XYZSource({
      attributions: bmapAttribution,
      crossOrigin: 'anonymous',
      tileGrid: bmapTilegrid,
      url: 'https://mapsneu.wien.gv.at/basemap/bmapgrau/normal/google3857/{z}/{y}/{x}.png'
    })
  });
  bmapGrauLayer.set('name', 'bmapGrau');

  const orthoGroup = createOrthoGroup();

  orthoGroup.set('name', 'orthofoto');

  const geoJsonFormat = new GeoJSON({
    featureProjection: 'EPSG:3857'
  });

  const stateBorders = new VectorLayer({
    //@ts-ignore
    name: 'bgldBorders',
    source: new VectorSource({
      format: geoJsonFormat,
      url: '/geodata/landesgrenze_bgld_epsg4326_simplify.json'
    }),
    style: new Style({
      stroke: new Stroke({
        width: 3.5,
        color: 'orangered'
      })
    }),
    opacity: 0.5
  });

  const regionBorders = new VectorLayer({
    //@ts-ignore
    name: 'regions',
    source: new VectorSource({
      format: geoJsonFormat,
      url: '/geodata/regionen_bgld_epsg4326_simplify.json'
    }),
    style: new Style({
      stroke: new Stroke({
        width: 2.5,
        color: 'orangered'
      })
    }),
    opacity: 0.5
  });

  const bordersGroup = new Group({
    layers: [
      stateBorders,
      regionBorders
    ]
  });
  bordersGroup.set('name', 'borders');

  return [stamenToner, bmapLayer, bmapGrauLayer, orthoGroup, bordersGroup];
}

/**
 * new instance of objekt layer
 * @param {*} objektId ID of objekt
 * @returns {import("ol/layer/Vector").default}
 */
export function createObjektLayer(objektId) {
  return new VectorLayer({
    zIndex: 2,
    //@ts-ignore
    name: 'objektLayer',
    source: objektSource,
    style: (feature, resolution) => {
      if (!objektId || feature.getId() === objektId) {
        return objektStyle(feature, resolution, false);
      } else {
        // show "other" objects as light gray
        return objektStyle(feature, resolution, true);
      }
    }
  });
}
