<template>
  <v-container
    fluid
    class="content pa-0"
  >
    <v-row class="mx-0 mt-4">
      <v-spacer />
      <v-col
        cols="10"
      >
        <v-card
          class="pa-5"
          elevation="6"
        >
          <v-card-title
            class="headline"
          >
            Impressum
          </v-card-title>
          <tinyMCE
            v-if="admin"
            v-model="ueber_text"
            :init="{
              content_css: false,
              skin: false,
              setup: function (ed) {
                ed.on('init', function () {
                  this.getDoc().body.style.fontSize = '12';
                  this.getDoc().body.style.fontFamily = 'Roboto, sans-serif';
                });
              },
              statusbar: false,
              height: 600,
              menubar: false,
              block_formats: 'Text=p; Überschrift 1=h1; Überschrift 2=h2',
              plugins: [
                'lists',
                'link',
                'paste'
              ],
              paste_as_text: true,
              language_url: '/langs/de.js',
              language: 'de',
              default_link_target: '_blank',
              toolbar:
                'undo redo | bold italic underline | link | bullist numlist outdent indent | formatselect | removeformat'
            }"
          />
          <v-card-text
            v-else
            class="contentText"
            v-html="ueber_text"
          />
        </v-card>
      </v-col>
      <v-spacer />
    </v-row>
    <TheTextbausteinConfirm v-if="admin" />
  </v-container>
</template>

<script>
import TheTextbausteinConfirm from '@/components/forms/TheTextbausteinConfirm.vue';
import tinyMCE from '@tinymce/tinymce-vue';
import bindingsTextbausteine from '@/store/bindingsTextbausteine';

export default {
  components: {
    tinyMCE,
    TheTextbausteinConfirm
  },
  props: {
    admin: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    ...bindingsTextbausteine()
  },
  async beforeRouteLeave(to, from, next) {
    if (this.admin && this.$store.state.isDirty) {
      await this.$root.$confirm({
        title: 'Zurück ohne Speichern?',
        message: 'Bitte bestätigen oder verwerfen Sie Ihre Änderungen.'
      });
    } else {
      next();
    }
  }
};
</script>

<style scoped>
  .content {
    height: calc(100vh - 92px);
    overflow: auto;
  }

  .fotoHolder {
    border-radius: 5px 5px 0px 0px !important;
  }

  .headline {
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
  }


</style>
