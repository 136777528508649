var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"content pa-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"mx-0 mt-4"},[_c('v-spacer'),_c('v-col',{attrs:{"cols":"10"}},[_c('v-card',{staticClass:"pa-5",attrs:{"elevation":"6"}},[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Impressum ")]),(_vm.admin)?_c('tinyMCE',{attrs:{"init":{
            content_css: false,
            skin: false,
            setup: function (ed) {
              ed.on('init', function () {
                this.getDoc().body.style.fontSize = '12';
                this.getDoc().body.style.fontFamily = 'Roboto, sans-serif';
              });
            },
            statusbar: false,
            height: 600,
            menubar: false,
            block_formats: 'Text=p; Überschrift 1=h1; Überschrift 2=h2',
            plugins: [
              'lists',
              'link',
              'paste'
            ],
            paste_as_text: true,
            language_url: '/langs/de.js',
            language: 'de',
            default_link_target: '_blank',
            toolbar:
              'undo redo | bold italic underline | link | bullist numlist outdent indent | formatselect | removeformat'
          }},model:{value:(_vm.ueber_text),callback:function ($$v) {_vm.ueber_text=$$v},expression:"ueber_text"}}):_c('v-card-text',{staticClass:"contentText",domProps:{"innerHTML":_vm._s(_vm.ueber_text)}})],1)],1),_c('v-spacer')],1),(_vm.admin)?_c('TheTextbausteinConfirm'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }