import {Model} from '@vuex-orm/core';

export default class PolitischeGemeinde extends Model {
  static entity = 'politischegemeinde';
  static fields() {
    return {
      id: this.number(null),
      name: this.string('')
    };
  }
}
