
/**
 * calculate left padding of map based on screen size
 * @param {*} vueInstance
 */
export function calculateShift(vueInstance) {
  const screenWidth = vueInstance.$vuetify.breakpoint.width;
  if (vueInstance.$vuetify.breakpoint.smAndDown) { // no shift
    return 0;
  }
  if (screenWidth < 1200) {
    return 670;
  }
  if (screenWidth < 1500) {
    return 750;
  }
  return 880;
}

export const bgldExtent = [1780692.8461290314, 5914472.751200247, 1910328.5119789578, 6126622.051117174];
