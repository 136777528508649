import {Style, Stroke, Icon, Fill} from 'ol/style';
import {objektFarben, switchResolution, objektIcons, normalIconResolution, fillColors} from './constants';
import CircleStyle from 'ol/style/Circle';
import {asArray} from 'ol/color';
import {isBpOrTz} from '@/utils/formatters';

const outlineStyle = new Style({
  stroke: new Stroke({
    width: 3,
    color: 'fuchsia'
  }),
  fill: new Fill({
    color: 'fuchsia'
  })
});

/**
 * a dashed outline style for BP and TZ
 */
const dashedOutlineStyle = new Style({
  stroke: new Stroke({
    width: 3,
    color: 'fuchsia',
    lineDash: [20, 13]
  }),
  fill: new Fill({
    color: 'fuchsia'
  })
});

/**
 * background stroke (grey) for bp and tz
 */
const dashedLineBackground = new Style({
  stroke: new Stroke({
    width: 6,
    color: '#c1c1c1'
  })
});

const anchors = {
  small: [0.76, 1],
  normal: [0.76, 1],
  large: [0.5, 1]
};

const objektStyles = {};
for (const key in objektIcons) {
  const differentSizeUrls = objektIcons[key];
  objektStyles[key] = {};
  for (const size in differentSizeUrls) {
    const url = differentSizeUrls[size];
    objektStyles[key][size] = new Style({
      image: new Icon({
        anchor: anchors[size],
        src: `/img/icons/${url}`,
        crossOrigin: ''
      }),
      zIndex: 3
    });
  }
}

export const orangeAnchorPoint = new Style({
  image: new CircleStyle({
    radius: 5,
    fill: new Fill({
      color: 'orange'
    })
  }),
  zIndex: 5
});

/**
 *
 * @param {*} feature
 * @param {number} resolution
 * @param {boolean} grey if true, a soft grey style will be used
 */
export function objektStyle(feature, resolution, grey) {
  if (!feature.get('typ')) {
    return;
  }
  const opacity = grey ? 0.35 : 1;
  orangeAnchorPoint.getImage().setOpacity(opacity);
  const style = feature.get('_hovered') ? [orangeAnchorPoint] : [];
  const typ = feature.get('typ');
  if (resolution < switchResolution) {
    const fillColor = [...fillColors[typ]];
    fillColor[3] = grey ? 0.1 : 0.2;
    const strokeColor = [...asArray(objektFarben[typ])];
    strokeColor[3] = opacity;
    // use dashed lines for bp and tz
    if (isBpOrTz(typ)) {
      dashedOutlineStyle.getStroke().setColor(strokeColor);
      dashedOutlineStyle.getFill().setColor(fillColor);
      dashedOutlineStyle.setZIndex(grey ? 0 : 1); // always keep the editing objekt up front
      dashedLineBackground.setZIndex(grey ? 0 : 1);
      style.unshift(dashedOutlineStyle);
      style.unshift(dashedLineBackground);
    } else {
      outlineStyle.getFill().setColor(fillColor);
      outlineStyle.getStroke().setColor(strokeColor);
      outlineStyle.setZIndex(grey ? 0 : 1); // always keep the editing objekt up front
      style.unshift(outlineStyle);
    }
    const iconStyle = objektStyles[typ]['large'];
    iconStyle.getImage().setOpacity(opacity);
    style.unshift(objektStyles[typ]['large']);
  } else if (resolution < normalIconResolution) {
    const iconStyle = objektStyles[typ]['normal'];
    iconStyle.getImage().setOpacity(opacity);
    style.unshift(iconStyle);
  } else {
    const iconStyle = objektStyles[feature.get('typ')]['small'];
    iconStyle.getImage().setOpacity(opacity);
    style.unshift(iconStyle);
    style.unshift(iconStyle);
  }
  return style;
}

export const vertexStyle = [new Style({
  image: new CircleStyle({
    radius: 5,
    stroke: new Stroke({
      color: 'white',
      width: 3
    })
  })
}), new Style({
  image: new CircleStyle({
    radius: 5,
    stroke: new Stroke({
      color: 'gray',
      width: 2
    })
  })
})];
