import { render, staticRenderFns } from "./Foerderungen.vue?vue&type=template&id=c6238668&scoped=true"
import script from "./Foerderungen.vue?vue&type=script&lang=js"
export * from "./Foerderungen.vue?vue&type=script&lang=js"
import style0 from "./Foerderungen.vue?vue&type=style&index=0&id=c6238668&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c6238668",
  null
  
)

export default component.exports