import '@mdi/font/css/materialdesignicons.css';
import 'tinymce/skins/ui/oxide/skin.css';
import 'tinymce/skins/ui/oxide/content.css';
import 'tinymce/skins/content/default/content.css';
import 'tinymce/tinymce';
import 'tinymce/themes/silver';
import 'tinymce/icons/default';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/link';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/wordcount';
import Vue from 'vue';
import App from '@/App.vue';
import vuetify from '@/plugins/vuetify';
import store from '@/store';
import router from '@/router';
import {createMapInstance} from '@/main/mapInstance';
import VueSocialSharing from 'vue-social-sharing';

Vue.prototype.$map = createMapInstance();
Vue.use(VueSocialSharing);

new Vue({
  render: h => h(App),
  store,
  router,
  //@ts-ignore
  vuetify
}).$mount('#app');

