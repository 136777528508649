<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    overlay-opacity="0.6"
    elevation="8"
    persistent
    @keydown.esc="cancel"
  >
    <v-card>
      <v-toolbar
        dark
        color="red darken-2 text-none"
        dense
        flat
      >
        <v-toolbar-title dark>
          {{ title }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text
        v-show="!!message"
        class="pa-4"
      >
        {{ message }}
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer />
        <v-btn
          color="primary darken-1"
          text
          @click.native="agree"
        >
          OK
        </v-btn>
        <v-btn
          color="grey"
          text
          @click.native="cancel"
        >
          Abbrechen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// warning component source:
// https://gist.github.com/eolant/ba0f8a5c9135d1a146e1db575276177d

/**
 * @typedef {Object} ConfirmOptions
 * @property {string} title confirm title
 * @property {string} message confirm message
 */

/**
 * Confirm-Component
 */
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null
  }),
  methods: {
    /**
     * @param {ConfirmOptions} args
     * @returns {Promise}
     */
    open(args) {
      this.dialog = true;
      this.title = args.title;
      this.message = args.message;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    }
  }
};
</script>
