<template>
  <v-app class="app">
    <LoadingScreen :is-loading="isLoading" />
    <Confirm ref="confirm" />
    <v-app-bar
      class="headerStyle"
      elevation="0"
      app
      short
      clipped-left
    >
      <a
        href="https://wirtschaftsagentur-burgenland.at"
        target="_blank"
      >
        <img
          class="logo"
          src="./images/logo_neu_181.png"
          alt="Wirtschaftsagentur Burgenland Logo"
        >
      </a>
      <v-toolbar-title class="headline customHeadline d-none d-sm-flex" />
      <v-spacer />
      <v-toolbar-items
        v-if="isAdmin === false"
        class="hidden-md-and-down"
      >
        <router-link
          v-for="(item, index) in headerItems"
          :key="index"
          v-slot="{ route, href, navigate }"
          :to="item.route"
          class="headerButton"
          active-class="activeRouterLink"
        >
          <a
            :href="href"
            :class="route.path === $route.path ? 'activeRouterLink' : ''"
            :aria-current="route.path === $route.path && 'page'"
            @click="navigate"
          ><slot>
            <v-btn
              :disabled="$route.params.admin"
              text
              tile
              :value="item.text"
              class="headerButton"
              :class="{
                'px-3': $vuetify.breakpoint.lgAndDown,
                'px-4': $vuetify.breakpoint.xl
              }"
            >
              <v-icon>{{ item.icon }}</v-icon>
              {{ item.text }}
            </v-btn></slot></a>
        </router-link>
        <a
          href="/doc/Datenerhebung_und_Zustimmungserklärungen_Standortdatenbank_2024.pdf"
          target="_blank"
        ><v-btn
          text
          tile
          class="headerButton"
          :class="{
            'px-3': $vuetify.breakpoint.lgAndDown,
            'px-4': $vuetify.breakpoint.xl
          }"
        >
          Download</v-btn></a>
      </v-toolbar-items>
      <v-toolbar-items
        v-if="isAdmin === true"
        class="hidden-sm-and-down"
      >
        <router-link
          v-for="(item, index) in adminHeaderItems"
          :key="index"
          v-slot="{ route, href, navigate }"
          :to="item.route"
          class="headerButton"
          active-class="activeRouterLink"
        >
          <a
            :href="href"
            :class="route.path === $route.path ? 'activeRouterLink' : ''"
            :aria-current="route.path === $route.path && 'page'"
            @click="navigate"
          ><slot>
            <v-btn
              :disabled="$route.params.admin"
              text
              tile
              :value="item.text"
              class="headerButton"
              :class="{
                'px-3': $vuetify.breakpoint.lgAndDown,
                'px-4': $vuetify.breakpoint.xl
              }"
            >
              {{ item.text }}
            </v-btn></slot></a>
        </router-link>
      </v-toolbar-items>


      <v-app-bar-nav-icon
        class="hidden-lg-and-up"
        aria-label="Navigation"
        @click.stop="drawer = !drawer"
      />
    </v-app-bar>

    <transition name="fade">
      <v-img
        :key="backgroundImage"
        class="background"
        :src="backgroundImage"
        width="100%"
      />
    </transition>
    <v-navigation-drawer
      v-model="drawer"
      class="navigationDrawer"
      absolute
      clipped
      width="100%"
    >
      <v-list>
        <v-list-item-group v-if="isAdmin === false">
          <v-list-item
            v-for="item in headerItems"
            :key="item.text"
            :to="item.route"
          >
            <v-icon>{{ item.icon }}</v-icon>
            <v-list-item-title>
              {{ item.text }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            href="/doc/Datenerhebung_und_Zustimmungserklärungen_Standortdatenbank_2024.pdf"
            target="_blank"
          >
            <v-list-item-title>Download</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
        <v-list-item-group v-else>
          <v-list-item
            v-for="item in adminHeaderItems"
            :key="item.text"
            :to="item.route"
          >
            <v-list-item-title>
              {{ item.text }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-main
      class="page"
    >
      <router-view
        v-if="!isLoading"
      />
    </v-main>
    <v-footer
      elevation="0"
      dark
      fixed
      padless
    >
      <v-row
        no-gutters
      >
        <div
          v-if="isAdmin === false"
          class="d-inline-flex footerDiv"
        >
          <v-btn
            v-for="footerItem in footerItems"
            :key="footerItem.text"
            :disabled="$route.params.admin"
            :to="footerItem.route"
            tag="button"
            text
            small
            rounded
            class="my-1"
          >
            {{ footerItem.text }}
          </v-btn>
          <v-checkbox
            v-model="barrierefrei"
            class="barrierefreiCheckbox ml-auto mr-4"
            dense
            hide-details
          >
            <template #label>
              <span class="checkboxLabel">barrierefrei</span>
            </template>
          </v-checkbox>
        </div>
        <div v-if="isAdmin === true">
          <v-btn
            v-for="footerItem in adminFooterItems"
            :key="footerItem.text"
            :to="footerItem.route"
            :target="footerItem.target"
            tag="button"
            text
            small
            rounded
            class="my-1"
            elevation="2"
          >
            <v-icon
              right
              dark
              class="mr-3"
            >
              {{ footerItem.icon }}
            </v-icon>
            {{ footerItem.text }}
          </v-btn>
          <v-btn
            text
            small
            rounded
            class="my-1 ml-5"
            href="/doc/wibug_handbuch-admin.pdf"
            target="_blank"
            elevation="2"
          >
            <v-icon
              right
              dark
              class="mr-3"
            >
              mdi-file-pdf-box
            </v-icon>
            PDF Handbuch
          </v-btn>
        </div>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import Confirm from '@/components/misc/Confirm.vue';
import LoadingScreen from './components/misc/Loading.vue';
import Bezirk from '@/models/bezirk';
import Infrastruktur from '@/models/infrastruktur';
import Katastralgemeinde from '@/models/katastralgemeinde';
import PolitischeGemeinde from '@/models/politischegemeinde';
import Widmung from '@/models/widmung';
import Textbausteine from '@/models/textbausteine';
import Objekt from '@/models/objekt';
import {bind as bindObjektSource} from '@/main/objektSource';

export default {
  name: 'App',
  components: {
    LoadingScreen,
    Confirm
  },
  data: () => ({
    drawer: false,
    isLoading: true,
    barrierefrei: false,
    loadingElements: {},
    headerItems: [
      {icon: 'mdi-home', text: '', route: '/'},
      {icon: '', text: 'Leerstand', route: '/leerstand'},
      {icon: '', text: 'Standortsuche', route: '/standortsuche'},
      {icon: '', text: 'Businessparks', route: '/businessparks'},
      {icon: '', text: 'Technologiezentren', route: '/technologiezentren'},
      {icon: '', text: 'Kontakt', route: '/kontakt'}
    ],
    adminHeaderItems: [
      {text: 'Objekte', route: '/admin'},
      {text: 'Textbausteine', route: '/textbausteine'},
      {text: 'logout', route: '/logout'}
    ],
    footerItems: [{
      text: 'Förderungen',
      route: 'foerderungen'
    },
    {
      text: 'Impressum',
      route: '/ueber'
    }, {
      text: 'Datenschutz',
      route: '/datenschutz'
    }],
    adminFooterItems: [{
      text: 'Kundenportal',
      route: '/',
      target: '_blank',
      icon: 'mdi-web'
    }],
    backgroundImage: ''
  }),
  computed: {
    isAdmin() {
      return this.$route.meta.requiresAuth;
    }
  },

  watch: {
    group() {
      this.drawer = false;
    },
    $route(to, from) {
      this.setBgImageByRoute();
    },
    '$route.query.barrierefrei'(value) {
      if (value !== this.barrierefrei) {
        this.barrierefrei = value ? true : false; // prevent undefined
      }
    },
    barrierefrei(value) {
      const router = this.$router;
      const currentRoute = this.$router.currentRoute;
      const query = currentRoute.query;
      const newQuery = Object.assign({}, query);
      // https://forum.vuejs.org/t/how-to-remove-one-query-string-from-url/39176/2
      newQuery.barrierefrei = value || undefined;
      const name = currentRoute.name;
      router.replace({
        name,
        params: currentRoute.params,
        query: newQuery
      }).catch(() => {});
    }
  },
  created() {
    bindObjektSource();
    this.$root.$on('loading', data => {
      this.isLoading = true;
      this.loadingElements[data] = true;
    });
    this.$root.$on('loaded', data => {
      delete this.loadingElements[data];
      this.checkLoaded(); // force computed
    });

    this.$root.$emit('loading', 'init-data');
    (async () => {
      try {
        await Promise.all([
          Bezirk.api().get('/api/bezirk'),
          Infrastruktur.api().get('/api/infrastruktur'),
          Katastralgemeinde.api().get('/api/katastralgemeinde'),
          PolitischeGemeinde.api().get('/api/politischegemeinde'),
          Widmung.api().get('/api/widmung'),
          Textbausteine.api().get('/api/textbausteine'),
          Objekt.api().get('/api/objekt')
        ]);

        this.$root.$emit('loaded', 'init-data');
        this.$store.commit('allLoaded');
      } catch (e) {
        throw new Error('Database Sync Error');
      }
    })();
  },
  mounted() {
    //@ts-ignore
    this.$root.$confirm = this.$refs.confirm.open;
    this.barrierefrei = !!this.$route.query.barrierefrei;
    this.setBgImageByRoute();
  },
  methods: {
    checkLoaded() {
      if (Object.keys(this.loadingElements).length === 0) {
        this.isLoading = false;
      }
    },
    setBgImageByRoute() {
      let routename = this.$route.name;
      if (this.$route.path == '/login') {
        routename = 'login';
      }
      const images = {
        'home': '/img/hintergrund-start.jpg',
        'foerderungen': '/img/hintergrund-foerderungen.jpg',
        'kontakt': '/img/hintergrund-kontakt.jpg',
        'datenschutz': '/img/hintergrund-kontakt.jpg',
        'login': '/img/hintergrund-kontakt.jpg',
        'ueber': '/img/hintergrund-kontakt.jpg'
      };
      this.backgroundImage = (typeof images[routename] !== 'undefined') ? images[routename] : '';
    },
    setRandomBgImage() {
      if (this.$route.meta.requiresAuth) {
        this.backgroundImage = '';
        return;
      }
      const images = [
        '/img/hgbilder/baustelle.jpg',
        '/img/hgbilder/glasfassade.jpg',
        '/img/hgbilder/lasercutting.jpg',
        '/img/hgbilder/logistik.jpg',
        '/img/hgbilder/podersdorf.jpg',
        '/img/hgbilder/technologiecenter.jpg',
        '/img/hgbilder/windraeder.jpg'
      ];
      const possibleImages = images.filter(i => i !== this.backgroundImage);
      this.backgroundImage = possibleImages[Math.floor(Math.random() * possibleImages.length)];
    }
  }
};
</script>

<style>
  .logo {
    height: 40px;
    width: 181px;
    margin-right: 20px;
  }

  .v-application .headline.customHeadline {
    height: 40px;
    border-left: 1px solid rgb(133,122,121);
    color: rgb(133,122,121);
    padding-left: 10px;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 40px;
  }

  .headline span {
    display: block;
  }

  .app {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  html {
    overflow-y: hidden!important;
  }

  .page {
    background-color: transparent;
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
  }
  .v-main__wrap {
    height: calc(100% - 37px);
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: .7;
    filter: blur(2px);
  }

  .fade-enter-active {
    transition: all 2s ease;
  }
  .fade-leave-active {
    transition: all 2s ease;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  html,body {
    overflow: auto;
    margin: 0px;
    padding: 0px;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
  }

  .activeRouterLink {
    background-color: rgba(0, 0, 0, 0.13) !important;
  }

    /* TINYMCE NOTIFICATION HACK */
  .tox-notifications-container {
    display: none !important;
  }

  .headerButtonHolder {
    background-color: transparent !important;
    height: 100%;
  }

  .headerButton{
    text-decoration: none;
    height: 100% !important;
  }

  .navigationDrawer {
    top: 56px !important;
    z-index: 10 !important;
    height: calc(100% - 92px) !important;
  }

  .barrierefreiCheckbox {
    position: fixed;
    right: -6px;
    bottom: 5px;
  }

  .footerDiv {
    width: 100%;
  }

  .checkboxLabel {
    color: white;
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 0.0892857143em;
    text-transform: uppercase;
    margin-left: -6px;
  }

  div.ol-control button {
    color: white;
    font-size: 1.2rem;
  }

  .ol-zoom.ol-control {
    background-color: rgba(0,0,0,.2);
    border-radius: 0px;
  }

  .ol-attribution {
    bottom: 15px!important;
  }

.ol-attribution.ol-unselectable.ol-control {
    background-color: rgba(255,255,255,.9);
    border-radius: 0px;
  }
  .ol-attribution.ol-unselectable.ol-control ul {
    font-size: 10px;
    line-height: 22px;
    color: #666;
  }
  .ol-attribution.ol-unselectable.ol-control ul a {
      color: #000;
  }

  .ol-attribution.ol-unselectable.ol-control.ol-collapsed {
    background-color: rgba(0,0,0,.2);
    border-radius: 0px;
  }

  .ol-zoom.ol-control:hover  {
    background-color: rgba(0,0,0,.3);
  }

  .ol-attribution.ol-unselectable.ol-control.ol-collapsed:hover {
    background-color: rgba(0,0,0,.3);
  }

  .ol-zoom.ol-zoom-in, .ol-zoom.ol-zoom-in {
    border-radius: 0px;
  }

  .ol-zoom.ol-control button, .ol-zoom.ol-control button:focus {
    background-color: rgba(0,0,0,.5);
  }
  .ol-zoom.ol-control button:hover {
    background-color: rgba(0,0,0,.8);
  }

  .ol-attribution.ol-unselectable.ol-control button, .ol-attribution.ol-unselectable.ol-control button:focus {
    background-color: rgba(0,0,0,.5);
  }

  .ol-attribution.ol-unselectable.ol-control button:hover {
    background-color: rgba(0,0,0,.8);
  }

  .contentText h1 {
    font-size:  22px;
    font-weight: bold;
    color: #666;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .contentText h2 {
    font-size:  18px;
    font-weight: bold;
    color: #999;
    padding-top: 5px;
    padding-bottom: 7px;
  }

  .theme--dark.v-sheet {
    border-top: 1px solid rgb(100,0,0)!important;
    background-color: rgb(160,0,0)!important;
    box-shadow: 0px -4px 4px rgba(0,0,0,.4)!important;
  }

  .theme--light.v-app-bar.v-toolbar.v-sheet {
    background-color: #fff!important;
    border-bottom: 1px solid rgb(100,100,100)!important;
    box-shadow: 0px 4px 4px rgba(0,0,0,.4)!important;
  }
</style>
