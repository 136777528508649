import {Database} from '@vuex-orm/core';
import Bezirk from '@/models/bezirk';
import Infrastruktur from '@/models/infrastruktur';
import Katastralgemeinde from '@/models/katastralgemeinde';
import PolitischeGemeinde from '@/models/politischegemeinde';
import Widmung from '@/models/widmung';
import Textbausteine from '@/models/textbausteine';
import Objekt from '@/models/objekt';

const database = new Database();

database.register(Bezirk);
database.register(Infrastruktur);
database.register(Katastralgemeinde);
database.register(PolitischeGemeinde);
database.register(Widmung);
database.register(Textbausteine);
database.register(Objekt);

export default database;
