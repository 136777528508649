import {Model} from '@vuex-orm/core';

export default class Infrastruktur extends Model {
  static entity = 'infrastruktur';
  static fields() {
    return {
      id: this.attr(null),
      bezeichnung: this.string('[]')
    };
  }
}
