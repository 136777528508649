<template>
  <v-container
    fluid
    class="content pa-0"
  >
    <v-row class="mx-0 spaceAbove">
      <v-spacer />
      <v-col
        lg="9"
        sm="10"
        cols="11"
      >
        <v-card class="px-9 py-3">
          <v-row>
            <v-col
              cols="12"
              lg="7"
            >
              <v-row no-gutters>
                <v-card-title class="pa-0 headline">
                  Ansprechpersonen
                </v-card-title>
              </v-row>

              <v-row no-gutters>
                Unsere MitarbeiterInnen kümmern sich sehr gerne um Ihre Anfragen:
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  class="personen pa-5"
                >
                  <v-row no-gutters>
                    <v-col
                      no-gutters
                      cols="6"
                      class="pr-3 mb-4"
                    >
                      <v-img
                        class="fotoHolder my-2"
                        width="150"
                        height="150"
                        src="/img/manuela_makovich_neu.jpg?size=300"
                        position="top center"
                      />
                      <div class="contactNameHolder">
                        Manuela Makovich
                      </div>
                    </v-col>
                    <v-col
                      no-gutters
                      cols="6"
                      class="pr-3"
                    >
                      <v-img
                        class="fotoHolder my-2"
                        width="150"
                        height="150"
                        src="/img/petra_vlasits_neu.jpg?size=300"
                        position="top center"
                      />
                      <div class="contactNameHolder">
                        Petra Vlasits
                      </div>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <tinyMCE
                      v-if="admin"
                      v-model="kontakt_eisenstadt_text"
                      :init="{
                        content_css: false,
                        skin: false,
                        setup: function (ed) {
                          ed.on('init', function () {
                            this.getDoc().body.style.fontSize = '12';
                            this.getDoc().body.style.fontFamily = 'Roboto, sans-serif';
                          });
                        },
                        statusbar: false,
                        width: '100%',
                        height: '300',
                        menubar: false,
                        block_formats: 'Text=p; Überschrift 1=h1; Überschrift 2=h2',
                        plugins: [
                          'lists',
                          'link',
                          'paste'
                        ],
                        paste_as_text: true,
                        language_url: '/langs/de.js',
                        language: 'de',
                        default_link_target: '_blank',
                        toolbar:
                          'undo redo | bold italic underline | link | bullist numlist outdent indent | formatselect | removeformat'
                      }"
                    />
                    <span
                      v-else
                      :class="{
                        barrierefrei: $route.query.barrierefrei
                      }"
                      v-html="kontakt_eisenstadt_text"
                    />
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  class="personen pa-5"
                >
                  <v-row no-gutters>
                    <v-col
                      no-gutters
                      cols="6"
                      class="pr-3 mb-4"
                    >
                      <v-img
                        class="fotoHolder my-2"
                        width="150"
                        height="150"
                        src="/img/michael_ditzer.jpg?size=300"
                        position="top center"
                      />
                      <div class="contactNameHolder">
                        Michael Ditzer
                      </div>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <tinyMCE
                      v-if="admin"
                      v-model="kontakt_guessing_text"
                      :init="{
                        content_css: false,
                        skin: false,
                        setup: function (ed) {
                          ed.on('init', function () {
                            this.getDoc().body.style.fontSize = '12';
                            this.getDoc().body.style.fontFamily = 'Roboto, sans-serif';
                          });
                        },
                        statusbar: false,
                        width: '100%',
                        height: '300',
                        menubar: false,
                        block_formats: 'Text=p; Überschrift 1=h1; Überschrift 2=h2',
                        plugins: [
                          'lists',
                          'link',
                          'paste'
                        ],
                        paste_as_text: true,
                        language_url: '/langs/de.js',
                        language: 'de',
                        default_link_target: '_blank',
                        toolbar:
                          'undo redo | bold italic underline | link | bullist numlist outdent indent | formatselect | removeformat'
                      }"
                    />
                    <span
                      v-else
                      :class="{
                        barrierefrei: $route.query.barrierefrei
                      }"
                      v-html="kontakt_guessing_text"
                    />
                  </v-row>
                </v-col>
              </v-row>

              <v-row
                v-if="admin"
                no-gutters
                class="bordertop"
              >
                Unsere Ansprechpersonen für die Technologiezentren:
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  class="personen pa-5"
                >
                  <v-row no-gutters>
                    <tinyMCE
                      v-if="admin"
                      v-model="tzkontakt_eisenstadt_text"
                      :init="{
                        content_css: false,
                        skin: false,
                        setup: function (ed) {
                          ed.on('init', function () {
                            this.getDoc().body.style.fontSize = '12';
                            this.getDoc().body.style.fontFamily = 'Roboto, sans-serif';
                          });
                        },
                        statusbar: false,
                        width: '100%',
                        height: '300',
                        menubar: false,
                        block_formats: 'Text=p; Überschrift 1=h1; Überschrift 2=h2',
                        plugins: [
                          'lists',
                          'link',
                          'paste'
                        ],
                        paste_as_text: true,
                        language_url: '/langs/de.js',
                        language: 'de',
                        default_link_target: '_blank',
                        toolbar:
                          'undo redo | bold italic underline | link | bullist numlist outdent indent | formatselect | removeformat'
                      }"
                    />
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  class="personen pa-5"
                >
                  <v-row no-gutters>
                    <tinyMCE
                      v-if="admin"
                      v-model="tzkontakt_guessing_text"
                      :init="{
                        content_css: false,
                        skin: false,
                        setup: function (ed) {
                          ed.on('init', function () {
                            this.getDoc().body.style.fontSize = '12';
                            this.getDoc().body.style.fontFamily = 'Roboto, sans-serif';
                          });
                        },
                        statusbar: false,
                        width: '100%',
                        height: '300',
                        menubar: false,
                        block_formats: 'Text=p; Überschrift 1=h1; Überschrift 2=h2',
                        plugins: [
                          'lists',
                          'link',
                          'paste'
                        ],
                        paste_as_text: true,
                        language_url: '/langs/de.js',
                        language: 'de',
                        default_link_target: '_blank',
                        toolbar:
                          'undo redo | bold italic underline | link | bullist numlist outdent indent | formatselect | removeformat'
                      }"
                    />
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-row
                no-gutters
                class="mapRow pt-8 pb-10"
              >
                <div
                  v-if="$route.query.barrierefrei"
                  class="accessibleMap"
                >
                  <v-img
                    src="img/kontakt-karte.jpg"
                    alt="Karte der Zweigstellen Eisenstadt und Heiligenkreuz"
                  />
                </div>
                <Map
                  v-else
                  :history="false"
                />
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-spacer />
    </v-row>
    <TheTextbausteinConfirm v-if="admin" />
  </v-container>
</template>

<script>
import Map from '@/components/map/TheMap.vue';
import {fromLonLat} from 'ol/proj';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import {Point} from 'ol/geom';
import {Style, Icon} from 'ol/style';
import TheTextbausteinConfirm from '@/components/forms/TheTextbausteinConfirm.vue';
import tinyMCE from '@tinymce/tinymce-vue';
import bindingsTextbausteine from '@/store/bindingsTextbausteine';
import {View} from 'ol';
import {createXYZ} from 'ol/tilegrid';
import {bgldExtent} from '@/utils/shift';

export default {
  components: {
    Map,
    tinyMCE,
    TheTextbausteinConfirm
  },
  props: {
    admin: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    ...bindingsTextbausteine()
  },
  mounted() {
    const eisenstadtCoords = fromLonLat([16.53204416757559, 47.830352584563656]);
    const guessingCoords = fromLonLat([16.27608067522481, 46.96906336522708]);

    this.layer = new VectorLayer({
      source: new VectorSource({
        features: [
          new Feature({
            geometry: new Point(eisenstadtCoords),
            properties: {
              name: 'Eisenstadt'
            }
          }),
          new Feature({
            geometry: new Point(guessingCoords),
            properties: {
              name: 'Güssing'
            }
          })
        ]
      }),
      style: new Style({
        image: new Icon({
          src: '/img/icons/kontakt_location.png',
          anchor: [0.5, 1],
          scale: 0.08,
          crossOrigin: ''
        })
      })
    });
    this.$map.addLayer(this.layer);
    this.oldView = this.$map.getView();
    this.$map.setView(new View({
      resolutions: createXYZ({tileSize: 512, maxZoom: 23}).getResolutions(),
      padding: [0, 0, 0, 0],
      extent: bgldExtent,
      constrainOnlyCenter: true,
      minZoom: 6
    }));
    this.$map.getView().fit(this.layer.getSource().getExtent(), {
      padding: [70, 70, 70, 70]
    });


  },
  updated() {
    // needed for reload 🤯
    this.$map.updateSize();
    this.$map.getView().fit(this.layer.getSource().getExtent(), {
      padding: [70, 70, 70, 70]
    });
  },
  beforeDestroy() {
    this.$map.removeLayer(this.layer);
    this.$map.setView(this.oldView);
  },
  async beforeRouteLeave(to, from, next) {
    if (this.admin && this.$store.state.isDirty) {
      await this.$root.$confirm({
        title: 'Zurück ohne Speichern?',
        message: 'Bitte bestätigen oder verwerfen Sie Ihre Änderungen.'
      });
    } else {
      next();
    }
  }
};
</script>
<style scoped>
  .content {
    height: calc(100vh - 92px);
    overflow: auto;
  }

  .fotoHolder {
    border-radius: 5px;
  }

  .mapRow {
    height: 100%;
    min-width: 220px;
    min-height: 220px;
  }

  .personen {
    min-width: 220px;
    max-width: 380px;
  }

.accessibleMap {
  position: relative;
  max-width: 500px;
  overflow: hidden;
  border-radius: 4px;
  text-align: right;
}

  .barrierefrei {
    font-size: 16px !important;
  }

  .spaceAbove {
    margin-top: 30px;
  }

  .contactNameHolder {
    font-size: 9pt;
  }

  .bordertop {
    border-top: 1px solid #eee;
    padding-top: 5px;
    font-weight: bold;
  }
</style>
