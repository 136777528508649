<template>
  <div id="mapWrapper">
    <div
      id="map"
      ref="mapContainer"
    />
  </div>
</template>

<script>
import 'ol/ol.css';
import {toLonLat, fromLonLat} from 'ol/proj';
import router from '@/router';
import {unByKey} from 'ol/Observable';
import {getFormattedCenter} from '@/utils/formatters';
import {bgldExtent} from '@/utils/shift';

const CENTER = [16.4185, 47.445];
const ZOOM = 8;

export default {
  props: {
    history: {
      type: Boolean,
      default: () => true
    }
  },
  watch: {
    $route(to, from) {
      this.updateMapView(to);
    }
  },

  mounted() {
    this.$map.setTarget(/** @type {HTMLElement} */ (this.$refs.mapContainer));
    this.$map.updateSize();
    this.syncRoute();
    if (this.$route.name === 'kontakt' || (!this.$route.query.zoom && !this.$route.query.center)) {
      this.$map.getView().fit(bgldExtent, {
        padding: [30, 30, 30, 30]
      });
    } else {
      this.updateMapView(router.currentRoute);
    }
  },

  updated() {
    this.$map.updateSize();
  },

  beforeDestroy() {
    unByKey(this.syncKey);
    this.syncKey = undefined;
  },

  methods: {

    /**
     * returns the current zoom rounded to 1 decimal.
     * @returns {string}
     */
    getMapZoomForRoute() {
      const zoom = this.$map.getView().getZoom() || ZOOM;
      return zoom.toFixed(1).replace(/\.?0+$/, '');
    },

    /**
     * returns the coordinates of the current center as comma-seperated string (WGS84)
     * @returns {string}
     */
    getMapCenterForRoute() {
      const center = toLonLat(this.$map.getView().getCenter() || CENTER);
      return getFormattedCenter(center);
    },

    /**
     * adds a moveend-listener to the map to sync the route with the map view.
     */
    syncRoute() {
      if (!this.syncKey) {
        this.syncKey = this.$map.on('moveend', event => {
          const query = router.currentRoute.query;
          const zoom = this.getMapZoomForRoute();
          const center = this.getMapCenterForRoute();
          if (this.history && (zoom !== query.zoom || center !== query.center)) {
            const newQuery = Object.assign({}, this.$router.currentRoute.query);
            router.push({
              params: router.currentRoute.params,
              query: Object.assign(newQuery, {zoom, center})
            });
          }
        });
      }
    },

    /**
     * updates the map view with the information of the given route.
     * Animates to zoom/center.
     * @param {*} route
     */
    updateMapView(route) {
      const zoom = this.getMapZoomForRoute();
      const center = this.getMapCenterForRoute();
      if (route.query.zoom !== zoom || route.query.center !== center) {
        const newCenter = fromLonLat(route.query.center ? route.query.center.split(',').map(Number) : CENTER);
        const newZoom = Number(route.query.zoom || ZOOM);
        const view = this.$map.getView();
        view.animate({
          zoom: newZoom,
          center: newCenter,
          duration: 250
        });
      }
    }
  }

};
</script>

<style scoped>
  #mapWrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-shadow: 0px 3px 6px rgba(0,0,0,.5);
  }

  #map {
    width: 100%;
    height: 100%;
    background-color: gray;
    overflow: hidden;
  }
</style>
