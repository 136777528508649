import {Model} from '@vuex-orm/core';

export default class Textbausteine extends Model {
  static entity = 'textbausteine';
  static fields() {
    return {
      id: this.attr(null),
      home_header: this.string(''),
      home_text_left: this.string(''),
      home_text_middle: this.string(''),
      home_text_middle2: this.string(''),
      home_text_right: this.string(''),
      home_title_left: this.string(''),
      home_title_middle: this.string(''),
      home_title_middle2: this.string(''),
      home_title_right: this.string(''),
      businessparks_text: this.string(''),
      foerderungen_header_text: this.string(''),
      foerderungen_1_title: this.string(''),
      foerderungen_1_text: this.string(''),
      foerderungen_1_link: this.string(''),
      foerderungen_2_title: this.string(''),
      foerderungen_2_text: this.string(''),
      foerderungen_2_link: this.string(''),
      foerderungen_3_title: this.string(''),
      foerderungen_3_text: this.string(''),
      foerderungen_3_link: this.string(''),
      foerderungen_4_title: this.string(''),
      foerderungen_4_text: this.string(''),
      foerderungen_4_link: this.string(''),
      foerderungen_5_title: this.string(''),
      foerderungen_5_text: this.string(''),
      foerderungen_5_link: this.string(''),
      kontakt_header_text: this.string(''),
      kontakt_eisenstadt_text: this.string(''),
      kontakt_guessing_text: this.string(''),
      ueber_text: this.string(''),
      datenschutz_text: this.string(''),
      tzkontakt_header_text: this.string(''),
      tzkontakt_eisenstadt_text: this.string(''),
      tzkontakt_guessing_text: this.string('')
    };
  }
}
