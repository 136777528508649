<template>
  <v-card
    width="340px"
    height="240px"
    class="pa-5 inthemiddle"
    elevation="4"
  >
    <v-form
      method="POST"
      action="/api/login"
    >
      <v-text-field
        ref="focusMe"
        name="username"
        label="Benutzername"
        placeholder="Benutzername"
        single
        outlined
        required
      />
      <v-text-field
        name="password"
        label="Passwort"
        placeholder="Password"
        required
        single
        outlined
        type="password"
      />
      <input
        name="next"
        type="hidden"
        :value="$route.query.next ? $route.query.next : '/admin/liste'"
      >
      <v-btn
        type="submit"
        color="primary"
      >
        Login
      </v-btn>
    </v-form>
  </v-card>
</template>
<script>
export default {
  mounted() {
    /** @type {HTMLInputElement} */ (this.$refs.focusMe).focus();
  }
};
</script>

<style scoped>
  .inthemiddle {
    position: absolute;
    left: calc(50% - 150px);
    top: 50px;
  }
</style>
