import {Query} from '@vuex-orm/core';
import Objekt from '../models/objekt';
import VectorSource from 'ol/source/Vector';
import ObjektFormat from '../utils/ObjektFormat';

/**
 * @type {VectorSource<import("ol/Feature.js").default<import("ol/geom/Geometry.js").default>>}
 */
const source = new VectorSource();

let bound = false;

export function bind() {
  if (bound) {
    throw new Error('objektSource already bound to store.');
  }
  bound = true;
  const format = new ObjektFormat();
  const initialItems = Objekt.all();
  source.addFeatures(format.readFeaturesFromObject(initialItems, {
    dataProjection: 'EPSG:4326',
    featureProjection: 'EPSG:3857'
  }));
  Query.on('afterCreate', model => {
    if (model instanceof Objekt) {
      const feature = format.readFeatureFromObject(model, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857'
      });
      source.addFeature(feature);
    }
  });
  Query.on('afterUpdate', model => {
    if (model instanceof Objekt) {
      const feature = format.readFeatureFromObject(model, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857'
      });
      source.getFeatureById(model.$id).setProperties(feature.getProperties());
    }
  });
  Query.on('afterDelete', model => {
    if (model instanceof Objekt) {
      source.removeFeature(source.getFeatureById(model.$id));
    }
  });
}

export default source;
