<template>
  <v-container
    fluid
    class="content pa-0"
  >
    <v-row
      class="spaceAbove"
      :class="{
        'mx-16': $vuetify.breakpoint.mdAndUp,
        'mx-6': $vuetify.breakpoint.smAndDown,
      }"
      justify="space-between"
    >
      <v-col
        lg="3"
        sm="6"
        cols="12"
        class="mx-0"
      >
        <v-hover
          v-slot="{ hover }"
        >
          <v-card
            class="transparentBack"
            :elevation="hover ? 10 : 6"
            :href="foerderungen_1_link"
            target="_blank"
          >
            <v-img
              class="fotoHolder"
              width="100%"
              height="140"
              src="/img/foerderung-tourismus.jpg"
              position="center center"
            />
            <v-sheet
              class="pa-4 backgroundGradient"
              :class="{'sameHeight' : !admin}"
            >
              <v-text-field
                v-if="admin"
                v-model="foerderungen_1_title"
                label="Titel 1"
                class="py-2"
                @click.native.prevent
              />
              <v-card-title
                v-else
                class="py-2"
              >
                {{ foerderungen_1_title }}
              </v-card-title>
              <v-text-field
                v-if="admin"
                v-model="foerderungen_1_link"
                label="Link 1 URL"
                class="py-2"
                @click.native.prevent
              />
              <div
                v-if="admin"
                class="mb-12"
              >
                <tinyMCE
                  v-model="foerderungen_1_text"
                  :init="{
                    content_css: false,
                    skin: false,
                    setup: function (ed) {
                      ed.on('init', function () {
                        this.getDoc().body.style.fontSize = '12';
                        this.getDoc().body.style.fontFamily = 'Roboto, sans-serif';
                      });
                    },
                    statusbar: false,
                    height: 300,
                    menubar: false,
                    block_formats: 'Text=p; Überschrift 1=h1; Überschrift 2=h2',
                    plugins: [
                      'lists',
                      'link',
                      'paste'
                    ],
                    paste_as_text: true,
                    language_url: '/langs/de.js',
                    language: 'de',
                    default_link_target: '_blank',
                    toolbar:
                      'undo redo | bold italic underline | link | bullist numlist outdent indent | formatselect | removeformat'
                  }"
                />
              </div>
              <v-card-text v-else>
                <span
                  :class="{
                    barrierefrei: $route.query.barrierefrei
                  }"
                  v-html="foerderungen_1_text"
                />
              </v-card-text>
            </v-sheet>
          </v-card>
        </v-hover>
      </v-col>
      <v-col
        lg="3"
        sm="6"
        cols="12"
        class="mx-0"
      >
        <v-hover
          v-slot="{ hover }"
        >
          <v-card
            class="transparentBack"
            :elevation="hover ? 10 : 6"
            :href="foerderungen_2_link"
            target="_blank"
          >
            <v-img
              class="fotoHolder"
              width="100%"
              height="140"
              src="/img/foerderung-gewerbeindustrie.jpg"
              position="center center"
            />
            <v-sheet
              class="pa-4 backgroundGradient"
              :class="{'sameHeight' : !admin}"
            >
              <v-text-field
                v-if="admin"
                v-model="foerderungen_2_title"
                label="Titel 2"
                class="py-2"
                @click.native.prevent
              />
              <v-card-title
                v-else
                class="py-2"
              >
                {{ foerderungen_2_title }}
              </v-card-title>
              <v-text-field
                v-if="admin"
                v-model="foerderungen_2_link"
                label="Link 2 URL"
                class="py-2"
                @click.native.prevent
              />
              <div
                v-if="admin"
                class="mb-12"
              >
                <tinyMCE
                  v-model="foerderungen_2_text"
                  :init="{
                    content_css: false,
                    skin: false,
                    setup: function (ed) {
                      ed.on('init', function () {
                        this.getDoc().body.style.fontSize = '12';
                        this.getDoc().body.style.fontFamily = 'Roboto, sans-serif';
                      });
                    },
                    statusbar: false,
                    height: 300,
                    menubar: false,
                    block_formats: 'Text=p; Überschrift 1=h1; Überschrift 2=h2',
                    plugins: [
                      'lists',
                      'link',
                      'paste'
                    ],
                    paste_as_text: true,
                    language_url: '/langs/de.js',
                    language: 'de',
                    default_link_target: '_blank',
                    toolbar:
                      'undo redo | bold italic underline | link | bullist numlist outdent indent | formatselect | removeformat'
                  }"
                />
              </div>
              <v-card-text v-else>
                <span
                  :class="{
                    barrierefrei: $route.query.barrierefrei
                  }"
                  v-html="foerderungen_2_text"
                />
              </v-card-text>
            </v-sheet>
          </v-card>
        </v-hover>
      </v-col>
      <v-col
        lg="3"
        sm="6"
        cols="12"
        class="mx-0"
      >
        <v-hover
          v-slot="{ hover }"
        >
          <v-card
            class="transparentBack"
            :elevation="hover ? 10 : 6"
            :href="foerderungen_3_link"
            target="_blank"
          >
            <v-img
              class="fotoHolder"
              width="100%"
              height="140"
              src="/img/foerderung-innovation.jpg"
              position="center center"
            />
            <v-sheet
              class="pa-4 backgroundGradient"
              :class="{'sameHeight' : !admin}"
            >
              <v-text-field
                v-if="admin"
                v-model="foerderungen_3_title"
                label="Titel 3"
                class="py-2"
                @click.native.prevent
              />
              <v-card-title
                v-else
                class="py-2"
              >
                {{ foerderungen_3_title }}
              </v-card-title>
              <v-text-field
                v-if="admin"
                v-model="foerderungen_3_link"
                label="Link 3 URL"
                class="py-2"
                @click.native.prevent
              />
              <div
                v-if="admin"
                class="mb-12"
              >
                <tinyMCE
                  v-model="foerderungen_3_text"
                  :init="{
                    content_css: false,
                    skin: false,
                    setup: function (ed) {
                      ed.on('init', function () {
                        this.getDoc().body.style.fontSize = '12';
                        this.getDoc().body.style.fontFamily = 'Roboto, sans-serif';
                      });
                    },
                    statusbar: false,
                    height: 300,
                    menubar: false,
                    block_formats: 'Text=p; Überschrift 1=h1; Überschrift 2=h2',
                    plugins: [
                      'lists',
                      'link',
                      'paste'
                    ],
                    paste_as_text: true,
                    language_url: '/langs/de.js',
                    language: 'de',
                    default_link_target: '_blank',
                    toolbar:
                      'undo redo | bold italic underline | link | bullist numlist outdent indent | formatselect | removeformat'
                  }"
                />
              </div>
              <v-card-text v-else>
                <span
                  :class="{
                    barrierefrei: $route.query.barrierefrei
                  }"
                  v-html="foerderungen_3_text"
                />
              </v-card-text>
            </v-sheet>
          </v-card>
        </v-hover>
      </v-col>
      <v-col
        lg="3"
        sm="6"
        cols="12"
        class="mx-0"
      >
        <v-hover
          v-slot="{ hover }"
        >
          <v-card
            class="transparentBack"
            :elevation="hover ? 10 : 6"
            :href="foerderungen_4_link"
            target="_blank"
          >
            <v-img
              class="fotoHolder"
              width="100%"
              height="140"
              src="/img/foerderung-finanzierungen.jpg"
              position="center center"
            />
            <v-sheet
              class="pa-4 backgroundGradient"
              :class="{'sameHeight' : !admin}"
            >
              <v-text-field
                v-if="admin"
                v-model="foerderungen_4_title"
                label="Titel 4"
                class="py-2"
                @click.native.prevent
              />
              <v-card-title
                v-else
                class="py-2"
              >
                {{ foerderungen_4_title }}
              </v-card-title>
              <v-text-field
                v-if="admin"
                v-model="foerderungen_4_link"
                label="Link 4 URL"
                class="py-2"
                @click.native.prevent
              />
              <div
                v-if="admin"
                class="mb-12"
              >
                <tinyMCE
                  v-model="foerderungen_4_text"
                  :init="{
                    content_css: false,
                    skin: false,
                    setup: function (ed) {
                      ed.on('init', function () {
                        this.getDoc().body.style.fontSize = '12';
                        this.getDoc().body.style.fontFamily = 'Roboto, sans-serif';
                      });
                    },
                    statusbar: false,
                    height: 300,
                    menubar: false,
                    block_formats: 'Text=p; Überschrift 1=h1; Überschrift 2=h2',
                    plugins: [
                      'lists',
                      'link',
                      'paste'
                    ],
                    paste_as_text: true,
                    language_url: '/langs/de.js',
                    language: 'de',
                    default_link_target: '_blank',
                    toolbar:
                      'undo redo | bold italic underline | link | bullist numlist outdent indent | formatselect | removeformat'
                  }"
                />
              </div>
              <v-card-text v-else>
                <span
                  :class="{
                    barrierefrei: $route.query.barrierefrei
                  }"
                  v-html="foerderungen_4_text"
                />
              </v-card-text>
            </v-sheet>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <div style="width: 5px; height: 150px;" />
    <TheTextbausteinConfirm v-if="admin" />
  </v-container>
</template>

<script>
import TheTextbausteinConfirm from '@/components/forms/TheTextbausteinConfirm.vue';
import tinyMCE from '@tinymce/tinymce-vue';
import bindingsTextbausteine from '@/store/bindingsTextbausteine';

export default {
  components: {
    tinyMCE,
    TheTextbausteinConfirm
  },
  props: {
    admin: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    ...bindingsTextbausteine()
  },
  async beforeRouteLeave(to, from, next) {
    if (this.admin && this.$store.state.isDirty) {
      await this.$root.$confirm({
        title: 'Zurück ohne Speichern?',
        message: 'Bitte bestätigen oder verwerfen Sie Ihre Änderungen.'
      });
    } else {
      next();
    }
  }
};
</script>
<style scoped>
  .content {
    height: calc(100vh - 92px);
    overflow: auto;
  }

  .fotoHolder {
    border-radius: 5px 5px 0px 0px !important;
  }

                    .barrierefrei {
    font-size: 16px !important;
  }

  .spaceAbove {
    margin-top: 30px;
  }

  .transparentBack {
    background: none;
  }

  .backgroundGradient {
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 60%, rgba(255,255,255,.4) 100%);
  }

  .sameHeight {
    height: 270px;
  }

  @media only screen and (max-width: 1720px) {
  .sameHeight {
          height: 300px;
    }
  }

  @media only screen and (max-width: 1500px) {
  .sameHeight {
      height: 390px;
    }
  }

  @media only screen and (max-width: 1263px) {
  .sameHeight {
      height: 250px;
    }
  }

  @media only screen and (max-width: 850px) {
  .sameHeight {
      height: 260px;
    }
  }

  @media only screen and (max-width: 829px) {
  .sameHeight {
      height: 400px;
    }
  }

  @media only screen and (max-width: 599px) {
  .sameHeight {
      height: auto;
    }
  }
</style>
