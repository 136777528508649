import {asArray} from 'ol/color';

export const objektFarben = {
  Grundstück: 'rgb(181,37,19)',
  Bürofläche: 'rgb(113,171,213)',
  Betriebsobjekt: 'rgb(247,166,0)',
  WPParndorf: '#6b5a53',
  WPMüllendorf: '#e87208',
  WPKittsee: '#6786bf',
  WPHeiligenkreuz: '#72972d',
  WPOberpullendorf: '#72972d',
  WPRudersdorf: '#72972d',
  TZEisenstadt: '#72972d',
  TZNeusiedl: '#72972d',
  TZMittelburgenland: '#72972d',
  TZPinkafeld: '#72972d',
  TZGuessing: '#72972d',
  TZJennersdorf: '#72972d'
};

// https://css-tricks.com/converting-color-spaces-in-javascript/
export function getHexColorForType(type) {
  let color = objektFarben[type];
  if (color[0] === '#') {
    return color;
  } else {
  // Choose correct separator
    const sep = color.indexOf(',') > -1 ? ',' : ' ';
    // Turn "rgb(r,g,b)" into [r,g,b]
    color = color.substr(4).split(')')[0].split(sep);

    let r = (+color[0]).toString(16),
        g = (+color[1]).toString(16),
        b = (+color[2]).toString(16);

    if (r.length == 1) {
      r = '0' + r;
    }
    if (g.length == 1) {
      g = '0' + g;
    }
    if (b.length == 1) {
      b = '0' + b;
    }

    return '#' + r + g + b;
  }
}

export const fillColors = Object.assign({}, objektFarben);
for (const key in fillColors) {
  const color = asArray(fillColors[key]);
  color[3] = 0.2;
  fillColors[key] = color;
}


export const objektIcons = {
  Grundstück: {
    small: 'icon30grundstueck.png',
    normal: 'icon48grundstueck.png',
    large: 'icon142grundstueck.png'
  },
  Bürofläche: {
    small: 'icon30office.png',
    normal: 'icon48office.png',
    large: 'icon142office.png'
  },
  Betriebsobjekt: {
    small: 'icon30gewerbe.png',
    normal: 'icon48gewerbe.png',
    large: 'icon142gewerbe.png'
  },
  WPParndorf: {
    small: 'icon30bpparndorf.png',
    normal: 'icon48bpparndorf.png',
    large: 'icon142bpparndorf.png'
  },
  WPMüllendorf: {
    small: 'icon30bpmuellendorf.png',
    normal: 'icon48bpmuellendorf.png',
    large: 'icon142bpmuellendorf.png'
  },
  WPKittsee: {
    small: 'icon30bpkittsee.png',
    normal: 'icon48bpkittsee.png',
    large: 'icon142bpkittsee.png'
  },
  WPHeiligenkreuz: {
    small: 'icon30bpheiligenkreuz.png',
    normal: 'icon48bpheiligenkreuz.png',
    large: 'icon142bpheiligenkreuz.png'
  },
  WPOberpullendorf: {
    small: 'icon30bpoberpullendorf.png',
    normal: 'icon48bpoberpullendorf.png',
    large: 'icon142bpoberpullendorf.png'
  },
  WPRudersdorf: {
    small: 'icon30bprudersdorf.png',
    normal: 'icon48bprudersdorf.png',
    large: 'icon142bprudersdorf.png'
  },
  TZEisenstadt: {
    small: 'icon30tzeisenstadt.png',
    normal: 'icon48tzeisenstadt.png',
    large: 'icon142tzeisenstadt.png'
  },
  TZNeusiedl: {
    small: 'icon30tzneusiedl.png',
    normal: 'icon48tzneusiedl.png',
    large: 'icon142tzneusiedl.png'
  },
  TZMittelburgenland: {
    small: 'icon30tzmittelburgenland.png',
    normal: 'icon48tzmittelburgenland.png',
    large: 'icon142tzmittelburgenland.png'
  },
  TZPinkafeld: {
    small: 'icon30tzpinkafeld.png',
    normal: 'icon48tzpinkafeld.png',
    large: 'icon142tzpinkafeld.png'
  },
  TZGuessing: {
    small: 'icon30tzguessing.png',
    normal: 'icon48tzguessing.png',
    large: 'icon142tzguessing.png'
  },
  TZJennersdorf: {
    small: 'icon30tzjennersdorf.png',
    normal: 'icon48tzjennersdorf.png',
    large: 'icon142tzjennersdorf.png'
  }
};

/**
 * resolution for:
 * switch between Basemap / Orthofoto
 * display objekt bounderies
 */
export const switchResolution = 4.78; // 2.42 for zoom 15

/**
 * resolution for normal sized icons
 * everything above will use small sized icons
 */
export const normalIconResolution = 36;
