import {Model} from '@vuex-orm/core';

export default class Bezirk extends Model {
  static entity = 'bezirk';
  static fields() {
    return {
      id: this.number(null),
      name: this.string('')
    };
  }
}
