<template>
  <v-container
    fluid
    class="content pa-0"
  >
    <v-card
      v-if="$route.query.barrierefrei"
      class="ma-10 pa-4"
      elevation="6"
      aria-label="Link zu Informationen über Technologiezentren der Wirtschaftsagentur Burgenland"
      href="https://wirtschaftsagentur-burgenland.at/standort/technologiezentren/"
      target="_blank"
    >
      <v-card-title>
        Informieren Sie sich über unsere Technologiezentren auf der Website der Wirtschaft Burgenland
      </v-card-title>
    </v-card>
    <div
      v-if="!$route.query.barrierefrei"
      ref="map"
      class="map"
    />
  </v-container>
</template>

<script>
import ImageLayer from 'ol/layer/Image';
import Map from 'ol/Map';
import Projection from 'ol/proj/Projection';
import Static from 'ol/source/ImageStatic';
import View from 'ol/View';
import {Feature} from 'ol';
import {Polygon} from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import {Fill, Style} from 'ol/style';
import {defaults as controlDefaults} from 'ol/control';
import {defaults as interactionDefaults} from 'ol/interaction';

let map;

export default {
  watch: {
    '$route.query.barrierefrei'(value) {
      if (!value) {
        this.initMap();
      }
    }
  },
  mounted() {
    this.initMap();
  },
  methods: {
    /**
     * creates an ol-polygon from corner points of a box of the image
     */
    createPolygonFromCornerPoints(x1, y1, x2, y2) {
      const fullHeight = 2745;
      return new Polygon([[[x1, fullHeight - y1], [x1, fullHeight - y2], [x2, fullHeight - y2], [x2, fullHeight - y1], [x1, fullHeight - y1]]]);
    },

    initMap() {
      this.$nextTick(() => {
        const extent = [0, 0, 1920, 2741];
        const projection = new Projection({
          code: 'image',
          units: 'pixels',
          extent: extent
        });

        const view = new View({
          projection,
          zoom: 2,
          maxZoom: 6
        });


        const eisenstadtLarge = new Feature({
          geometry: this.createPolygonFromCornerPoints(975, 31, 1241, 662),
          url: 'https://www.tz-burgenland.at/technologiezentrum-eisenstadt/',
          type: 'large'
        });
        const eisenstadtSmall = new Feature({
          geometry: this.createPolygonFromCornerPoints(975, 383, 1241, 662),
          url: 'https://www.tz-burgenland.at/technologiezentrum-eisenstadt/',
          type: 'small'
        });
        const pinkafeldLarge = new Feature({
          geometry: this.createPolygonFromCornerPoints(154, 985, 420, 1639),
          url: 'https://www.tz-burgenland.at/technologiezentrum-pinkafeld/',
          type: 'large'
        });
        const pinkafeldSmall = new Feature({
          geometry: this.createPolygonFromCornerPoints(154, 985, 420, 1269),
          url: 'https://www.tz-burgenland.at/technologiezentrum-pinkafeld/',
          type: 'small'
        });
        const jennersdorfLarge = new Feature({
          geometry: this.createPolygonFromCornerPoints(278, 2064, 542, 2694),
          url: 'https://www.tz-burgenland.at/technologiezentrum-jennersdorf/',
          type: 'large'
        });
        const jennersdorfSmall = new Feature({
          geometry: this.createPolygonFromCornerPoints(278, 2064, 542, 2340),
          url: 'https://www.tz-burgenland.at/technologiezentrum-jennersdorf/',
          type: 'small'
        });
        const guessingLarge = new Feature({
          geometry: this.createPolygonFromCornerPoints(1117, 1802, 1382, 2435),
          url: 'https://www.tz-burgenland.at/technologiezentrum-guessing/',
          type: 'large'
        });
        const guessingSmall = new Feature({
          geometry: this.createPolygonFromCornerPoints(1117, 1802, 1382, 2082),
          url: 'https://www.tz-burgenland.at/technologiezentrum-guessing/',
          type: 'small'
        });
        const mittelburgenlandLarge = new Feature({
          geometry: this.createPolygonFromCornerPoints(1123, 1147, 1383, 1752),
          url: 'https://www.tz-burgenland.at/technologiezentrum-mittelburgenland/',
          type: 'large'
        });
        const mittelburgenlandSmall = new Feature({
          geometry: this.createPolygonFromCornerPoints(1123, 1147, 1383, 1424),
          url: 'https://www.tz-burgenland.at/technologiezentrum-mittelburgenland/',
          type: 'small'
        });
        const neusiedlLarge = new Feature({
          geometry: this.createPolygonFromCornerPoints(1440, 1005, 1702, 1654),
          url: 'https://www.tz-burgenland.at/technologiezentrum-neusiedl-am-see/',
          type: 'large'
        });
        const neusiedlSmall = new Feature({
          geometry: this.createPolygonFromCornerPoints(1440, 1005, 1702, 1285),
          url: 'https://www.tz-burgenland.at/technologiezentrum-neusiedl-am-see/',
          type: 'small'
        });

        const clickAreasLayer = new VectorLayer({
          source: new VectorSource({
            features: [eisenstadtLarge, eisenstadtSmall, pinkafeldSmall, pinkafeldLarge,
              jennersdorfSmall, jennersdorfLarge, guessingSmall, guessingLarge,
              mittelburgenlandLarge, mittelburgenlandSmall, neusiedlLarge, neusiedlSmall]
          }),
          style: (feature, resolution) => {
            // handle visibility differently for small and large boxes
            const type = feature.get('type');
            const zoom = view.getZoomForResolution(resolution);
            if (type === 'small' && zoom >= 2.7 || type === 'large' && zoom < 2.7) {
              return;
            } else {
              return new Style({
                fill: new Fill({
                  color: 'rgba(0, 0, 0, 0)'
                })
              });
            }
          }
        });

        map = new Map({
          layers: [
            new ImageLayer({
              source: new Static({
                url: '/img/technoz-parks-plan-detail-1.jpg',
                projection: projection,
                imageExtent: extent
              }),
              // dont set same zoom to prevent flicker
              minZoom: 1
            }),
            new ImageLayer({
              source: new Static({
                url: '/img/technoz-parks-plan-detail-2.jpg',
                projection: projection,
                imageExtent: extent
              }),
              maxZoom: 2.7
            }),
            clickAreasLayer
          ],
          //@ts-ignore
          target: this.$refs.map,
          view,
          controls: controlDefaults({
            rotate: false
          }),
          interactions: interactionDefaults({
            altShiftDragRotate: false,
            pinchRotate: false
          })
        });
        view.fit(extent, {
          padding: [50, 50, 50, 50]
        });
        map.on('click', this.mapClick);
        map.on('pointermove', this.mapHover);
      });
    },
    mapClick: function(evt) {
      const feature = map.forEachFeatureAtPixel(evt.pixel, feature => feature);
      if (feature) {
        window.open(feature.get('url'), '_blank');
      }
    },
    mapHover: function(evt) {
      map.getTargetElement().style.cursor = map.hasFeatureAtPixel(evt.pixel) ? 'pointer' : '';
    }
  }
};
</script>

<style scoped>
  .content {
    height: calc(100vh - 92px);
    overflow: auto;
    background-color: white;
  }

  .map {
    height: 100%;
    width: 100%;
  }

</style>
