<template>
  <div>
    <v-fab-transition>
      <v-btn
        v-show="!$store.state.isDirty"
        class="back"
        color="error"
        dark
        fixed
        x-large
        to="textbausteine"
      >
        zurück zu Auswahl
      </v-btn>
    </v-fab-transition>
    <v-fab-transition>
      <v-btn
        v-show="$store.state.isDirty"
        class="cancel"
        color="error"
        fab
        dark
        fixed
        x-large
        @click="reset"
      >
        <v-icon>mdi-cancel</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-fab-transition>
      <v-btn
        v-show="$store.state.isDirty"
        class="confirm"
        color="success"
        fab
        dark
        fixed
        x-large
        @click="save"
      >
        <v-icon>mdi-check</v-icon>
      </v-btn>
    </v-fab-transition>
  </div>
</template>


<script>
import Textbausteine from '@/models/textbausteine';
export default {
  mounted() {
    this.$store.dispatch('isDirty', false);
    this.restore = {...Textbausteine.query().first().$getAttributes()};
  },
  methods: {
    save() {
      this.$store.dispatch('isDirty', false);
      const textbausteine = Textbausteine.query().first();
      const data = textbausteine.$getAttributes();
      Textbausteine.api().put(`/api/textbausteine/${textbausteine.$id}`, data);
    },
    reset() {
      Textbausteine.update({
        data: this.restore
      });
      this.$store.dispatch('isDirty', false);
    }
  }
};

</script>

<style scoped>
  .confirm {
    z-index: 2;
    bottom: 50px;
    left: calc(50% + 50px);
  }
  .cancel {
    z-index: 2;
    bottom: 50px;
    left: calc(50% - 50px);
  }

  .back {
    z-index: 2;
    bottom: 50px;
    left: calc(50% - 111px);
  }
</style>
