var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"content pa-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"mx-0 spaceAbove"},[_c('v-spacer'),_c('v-col',{attrs:{"lg":"9","sm":"10","cols":"11"}},[_c('v-card',{staticClass:"px-9 py-3"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"7"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-card-title',{staticClass:"pa-0 headline"},[_vm._v(" Ansprechpersonen ")])],1),_c('v-row',{attrs:{"no-gutters":""}},[_vm._v(" Unsere MitarbeiterInnen kümmern sich sehr gerne um Ihre Anfragen: ")]),_c('v-row',[_c('v-col',{staticClass:"personen pa-5",attrs:{"cols":"12","sm":"6"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-3 mb-4",attrs:{"no-gutters":"","cols":"6"}},[_c('v-img',{staticClass:"fotoHolder my-2",attrs:{"width":"150","height":"150","src":"/img/manuela_makovich_neu.jpg?size=300","position":"top center"}}),_c('div',{staticClass:"contactNameHolder"},[_vm._v(" Manuela Makovich ")])],1),_c('v-col',{staticClass:"pr-3",attrs:{"no-gutters":"","cols":"6"}},[_c('v-img',{staticClass:"fotoHolder my-2",attrs:{"width":"150","height":"150","src":"/img/petra_vlasits_neu.jpg?size=300","position":"top center"}}),_c('div',{staticClass:"contactNameHolder"},[_vm._v(" Petra Vlasits ")])],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[(_vm.admin)?_c('tinyMCE',{attrs:{"init":{
                      content_css: false,
                      skin: false,
                      setup: function (ed) {
                        ed.on('init', function () {
                          this.getDoc().body.style.fontSize = '12';
                          this.getDoc().body.style.fontFamily = 'Roboto, sans-serif';
                        });
                      },
                      statusbar: false,
                      width: '100%',
                      height: '300',
                      menubar: false,
                      block_formats: 'Text=p; Überschrift 1=h1; Überschrift 2=h2',
                      plugins: [
                        'lists',
                        'link',
                        'paste'
                      ],
                      paste_as_text: true,
                      language_url: '/langs/de.js',
                      language: 'de',
                      default_link_target: '_blank',
                      toolbar:
                        'undo redo | bold italic underline | link | bullist numlist outdent indent | formatselect | removeformat'
                    }},model:{value:(_vm.kontakt_eisenstadt_text),callback:function ($$v) {_vm.kontakt_eisenstadt_text=$$v},expression:"kontakt_eisenstadt_text"}}):_c('span',{class:{
                      barrierefrei: _vm.$route.query.barrierefrei
                    },domProps:{"innerHTML":_vm._s(_vm.kontakt_eisenstadt_text)}})],1)],1),_c('v-col',{staticClass:"personen pa-5",attrs:{"cols":"12","sm":"6"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-3 mb-4",attrs:{"no-gutters":"","cols":"6"}},[_c('v-img',{staticClass:"fotoHolder my-2",attrs:{"width":"150","height":"150","src":"/img/michael_ditzer.jpg?size=300","position":"top center"}}),_c('div',{staticClass:"contactNameHolder"},[_vm._v(" Michael Ditzer ")])],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[(_vm.admin)?_c('tinyMCE',{attrs:{"init":{
                      content_css: false,
                      skin: false,
                      setup: function (ed) {
                        ed.on('init', function () {
                          this.getDoc().body.style.fontSize = '12';
                          this.getDoc().body.style.fontFamily = 'Roboto, sans-serif';
                        });
                      },
                      statusbar: false,
                      width: '100%',
                      height: '300',
                      menubar: false,
                      block_formats: 'Text=p; Überschrift 1=h1; Überschrift 2=h2',
                      plugins: [
                        'lists',
                        'link',
                        'paste'
                      ],
                      paste_as_text: true,
                      language_url: '/langs/de.js',
                      language: 'de',
                      default_link_target: '_blank',
                      toolbar:
                        'undo redo | bold italic underline | link | bullist numlist outdent indent | formatselect | removeformat'
                    }},model:{value:(_vm.kontakt_guessing_text),callback:function ($$v) {_vm.kontakt_guessing_text=$$v},expression:"kontakt_guessing_text"}}):_c('span',{class:{
                      barrierefrei: _vm.$route.query.barrierefrei
                    },domProps:{"innerHTML":_vm._s(_vm.kontakt_guessing_text)}})],1)],1)],1),(_vm.admin)?_c('v-row',{staticClass:"bordertop",attrs:{"no-gutters":""}},[_vm._v(" Unsere Ansprechpersonen für die Technologiezentren: ")]):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"personen pa-5",attrs:{"cols":"12","sm":"6"}},[_c('v-row',{attrs:{"no-gutters":""}},[(_vm.admin)?_c('tinyMCE',{attrs:{"init":{
                      content_css: false,
                      skin: false,
                      setup: function (ed) {
                        ed.on('init', function () {
                          this.getDoc().body.style.fontSize = '12';
                          this.getDoc().body.style.fontFamily = 'Roboto, sans-serif';
                        });
                      },
                      statusbar: false,
                      width: '100%',
                      height: '300',
                      menubar: false,
                      block_formats: 'Text=p; Überschrift 1=h1; Überschrift 2=h2',
                      plugins: [
                        'lists',
                        'link',
                        'paste'
                      ],
                      paste_as_text: true,
                      language_url: '/langs/de.js',
                      language: 'de',
                      default_link_target: '_blank',
                      toolbar:
                        'undo redo | bold italic underline | link | bullist numlist outdent indent | formatselect | removeformat'
                    }},model:{value:(_vm.tzkontakt_eisenstadt_text),callback:function ($$v) {_vm.tzkontakt_eisenstadt_text=$$v},expression:"tzkontakt_eisenstadt_text"}}):_vm._e()],1)],1),_c('v-col',{staticClass:"personen pa-5",attrs:{"cols":"12","sm":"6"}},[_c('v-row',{attrs:{"no-gutters":""}},[(_vm.admin)?_c('tinyMCE',{attrs:{"init":{
                      content_css: false,
                      skin: false,
                      setup: function (ed) {
                        ed.on('init', function () {
                          this.getDoc().body.style.fontSize = '12';
                          this.getDoc().body.style.fontFamily = 'Roboto, sans-serif';
                        });
                      },
                      statusbar: false,
                      width: '100%',
                      height: '300',
                      menubar: false,
                      block_formats: 'Text=p; Überschrift 1=h1; Überschrift 2=h2',
                      plugins: [
                        'lists',
                        'link',
                        'paste'
                      ],
                      paste_as_text: true,
                      language_url: '/langs/de.js',
                      language: 'de',
                      default_link_target: '_blank',
                      toolbar:
                        'undo redo | bold italic underline | link | bullist numlist outdent indent | formatselect | removeformat'
                    }},model:{value:(_vm.tzkontakt_guessing_text),callback:function ($$v) {_vm.tzkontakt_guessing_text=$$v},expression:"tzkontakt_guessing_text"}}):_vm._e()],1)],1)],1)],1),_c('v-col',[_c('v-row',{staticClass:"mapRow pt-8 pb-10",attrs:{"no-gutters":""}},[(_vm.$route.query.barrierefrei)?_c('div',{staticClass:"accessibleMap"},[_c('v-img',{attrs:{"src":"img/kontakt-karte.jpg","alt":"Karte der Zweigstellen Eisenstadt und Heiligenkreuz"}})],1):_c('Map',{attrs:{"history":false}})],1)],1)],1)],1)],1),_c('v-spacer')],1),(_vm.admin)?_c('TheTextbausteinConfirm'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }