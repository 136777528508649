var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"content pa-0",attrs:{"fluid":""}},[(_vm.admin)?_c('v-row',{class:{
      'mx-16': _vm.$vuetify.breakpoint.mdAndUp,
      'mx-6': _vm.$vuetify.breakpoint.smAndDown,
      'my-8': _vm.$vuetify.breakpoint.mdAndUp,
      'my-2': _vm.$vuetify.breakpoint.smAndDown
    }},[_c('v-spacer'),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-5",attrs:{"elevation":"6"}},[_c('tinyMCE',{attrs:{"init":{
            content_css: false,
            skin: false,
            setup: function (ed) {
              ed.on('init', function () {
                this.getDoc().body.style.fontSize = '12';
                this.getDoc().body.style.fontFamily = 'Roboto, sans-serif';
              });
            },
            statusbar: false,
            height: 200,
            menubar: false,
            paste_as_text: true,
            language_url: '/langs/de.js',
            language: 'de',
            block_formats: 'Text=p;',
            toolbar:
              'undo redo | bold italic underline '
          }},model:{value:(_vm.home_header),callback:function ($$v) {_vm.home_header=$$v},expression:"home_header"}})],1)],1),_c('v-spacer')],1):_vm._e(),(!_vm.admin && _vm.carouselTextItems.length)?_c('v-row',{attrs:{"no-gutters":""}},[_c('div',{staticClass:"carouselHolder"},[_c('VueSlickCarousel',{attrs:{"arrows":false,"fade":true,"infinite":true,"speed":3000,"autoplay-speed":5000,"autoplay":true}},[_vm._l((_vm.carouselTextItems),function(item){return _c('div',{key:item,class:{
            'carouselDiv': true,
            'smallCarousel': _vm.$vuetify.breakpoint.mdAndDown,
            'largeCarousel': _vm.$vuetify.breakpoint.lgOnly,
            'xlCarousel': _vm.$vuetify.breakpoint.xlOnly
          }},[_vm._v(" "+_vm._s(item)+" ")])}),_vm._v(" /> ")],2)],1)]):_vm._e(),_c('v-row',{class:{
      'mx-16': _vm.$vuetify.breakpoint.mdAndUp,
      'mx-6': _vm.$vuetify.breakpoint.smAndDown
    },attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"mx-0",attrs:{"lg":"3","sm":"6","cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{staticClass:"transparentBack",attrs:{"elevation":hover ? 10 : 6},on:{"click":function($event){return _vm.clickHandler('leerstand', $event)}}},[_c('v-img',{staticClass:"fotoHolder",attrs:{"width":"100%","height":"140","src":"/img/startseite-leerstand.jpeg","position":"bottom center"}}),_c('v-sheet',{staticClass:"pa-4 backgroundGradient",class:{'sameHeight' : !_vm.admin}},[(_vm.admin)?_c('v-text-field',{staticClass:"py-2",attrs:{"label":"Titel links"},model:{value:(_vm.home_title_left),callback:function ($$v) {_vm.home_title_left=$$v},expression:"home_title_left"}}):_c('v-card-title',{staticClass:"py-2"},[_vm._v(" "+_vm._s(_vm.home_title_left)+" ")]),(_vm.admin)?_c('tinyMCE',{attrs:{"init":{
                content_css: false,
                skin: false,
                setup: function (ed) {
                  ed.on('init', function () {
                    this.getDoc().body.style.fontSize = '12';
                    this.getDoc().body.style.fontFamily = 'Roboto, sans-serif';
                  });
                },
                statusbar: false,
                height: 300,
                menubar: false,
                language_url: '/langs/de.js',
                language: 'de',
                block_formats: 'Text=p; Überschrift 1=h1; Überschrift 2=h2',
                plugins: [
                  'lists',
                  'link',
                  'paste'
                ],
                paste_as_text: true,
                default_link_target: '_blank',
                toolbar:
                  'undo redo | bold italic underline | link | bullist numlist outdent indent | formatselect | removeformat'
              }},model:{value:(_vm.home_text_left),callback:function ($$v) {_vm.home_text_left=$$v},expression:"home_text_left"}}):_c('v-card-text',[_c('span',{class:{
                  barrierefrei: _vm.$route.query.barrierefrei
                },domProps:{"innerHTML":_vm._s(_vm.home_text_left)}})])],1)],1)]}}])})],1),_c('v-col',{staticClass:"mx-0",attrs:{"lg":"3","sm":"6","cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{staticClass:"transparentBack",attrs:{"elevation":hover ? 10 : 6},on:{"click":function($event){return _vm.clickHandler('standortsuche', $event)}}},[_c('v-img',{staticClass:"fotoHolder",attrs:{"width":"100%","height":"140","src":"/img/startseite-standortsuche.jpg","position":"center center"}}),_c('v-sheet',{staticClass:"pa-4 backgroundGradient",class:{'sameHeight' : !_vm.admin}},[(_vm.admin)?_c('v-text-field',{staticClass:"py-2",attrs:{"label":"Titel Mitte links"},model:{value:(_vm.home_title_middle),callback:function ($$v) {_vm.home_title_middle=$$v},expression:"home_title_middle"}}):_c('v-card-title',{staticClass:"py-2"},[_vm._v(" "+_vm._s(_vm.home_title_middle)+" ")]),(_vm.admin)?_c('tinyMCE',{attrs:{"init":{
                content_css: false,
                skin: false,
                setup: function (ed) {
                  ed.on('init', function () {
                    this.getDoc().body.style.fontSize = '12';
                    this.getDoc().body.style.fontFamily = 'Roboto, sans-serif';
                  });
                },
                statusbar: false,
                height: 300,
                menubar: false,
                language_url: '/langs/de.js',
                language: 'de',
                block_formats: 'Text=p; Überschrift 1=h1; Überschrift 2=h2',
                plugins: [
                  'lists',
                  'link',
                  'paste'
                ],
                paste_as_text: true,
                default_link_target: '_blank',
                toolbar:
                  'undo redo | bold italic underline | link | bullist numlist outdent indent | formatselect | removeformat'
              }},model:{value:(_vm.home_text_middle),callback:function ($$v) {_vm.home_text_middle=$$v},expression:"home_text_middle"}}):_c('v-card-text',[_c('span',{class:{
                  barrierefrei: _vm.$route.query.barrierefrei
                },domProps:{"innerHTML":_vm._s(_vm.home_text_middle)}})])],1)],1)]}}])})],1),_c('v-col',{staticClass:"mx-0",attrs:{"lg":"3","sm":"6","cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{staticClass:"transparentBack",attrs:{"elevation":hover ? 10 : 6},on:{"click":function($event){return _vm.clickHandler('businessparks', $event)}}},[_c('v-img',{staticClass:"fotoHolder",attrs:{"width":"100%","height":"140","src":"/img/startseite-businessparks.jpeg","position":"center center"}}),_c('v-sheet',{staticClass:"pa-4 backgroundGradient",class:{'sameHeight' : !_vm.admin}},[(_vm.admin)?_c('v-text-field',{staticClass:"py-2",attrs:{"label":"Titel Mitte rechts"},model:{value:(_vm.home_title_middle2),callback:function ($$v) {_vm.home_title_middle2=$$v},expression:"home_title_middle2"}}):_c('v-card-title',{staticClass:"py-2"},[_vm._v(" "+_vm._s(_vm.home_title_middle2)+" ")]),(_vm.admin)?_c('tinyMCE',{attrs:{"init":{
                content_css: false,
                skin: false,
                setup: function (ed) {
                  ed.on('init', function () {
                    this.getDoc().body.style.fontSize = '12';
                    this.getDoc().body.style.fontFamily = 'Roboto, sans-serif';
                  });
                },
                statusbar: false,
                height: 300,
                menubar: false,
                language_url: '/langs/de.js',
                language: 'de',
                block_formats: 'Text=p; Überschrift 1=h1; Überschrift 2=h2',
                plugins: [
                  'lists',
                  'link',
                  'paste'
                ],
                paste_as_text: true,
                default_link_target: '_blank',
                toolbar:
                  'undo redo | bold italic underline | link | bullist numlist outdent indent | formatselect | removeformat'
              }},model:{value:(_vm.home_text_middle2),callback:function ($$v) {_vm.home_text_middle2=$$v},expression:"home_text_middle2"}}):_c('v-card-text',[_c('span',{class:{
                  barrierefrei: _vm.$route.query.barrierefrei
                },domProps:{"innerHTML":_vm._s(_vm.home_text_middle2)}})])],1)],1)]}}])})],1),_c('v-col',{staticClass:"mx-0",attrs:{"lg":"3","sm":"6","cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{staticClass:"transparentBack",attrs:{"elevation":hover ? 10 : 6},on:{"click":function($event){return _vm.clickHandler('technologiezentren', $event)}}},[_c('v-img',{staticClass:"fotoHolder",attrs:{"width":"100%","height":"140","src":"/img/startseite-technologiezentren.jpeg","position":"center center"}}),_c('v-sheet',{staticClass:"pa-4 backgroundGradient",class:{'sameHeight' : !_vm.admin}},[(_vm.admin)?_c('v-text-field',{staticClass:"py-2",attrs:{"label":"Titel rechts"},model:{value:(_vm.home_title_right),callback:function ($$v) {_vm.home_title_right=$$v},expression:"home_title_right"}}):_c('v-card-title',{staticClass:"py-2"},[_vm._v(" "+_vm._s(_vm.home_title_right)+" ")]),(_vm.admin)?_c('tinyMCE',{attrs:{"init":{
                content_css: false,
                skin: false,
                setup: function (ed) {
                  ed.on('init', function () {
                    this.getDoc().body.style.fontSize = '12';
                    this.getDoc().body.style.fontFamily = 'Roboto, sans-serif';
                  });
                },
                statusbar: false,
                height: 300,
                menubar: false,
                language_url: '/langs/de.js',
                language: 'de',
                block_formats: 'Text=p; Überschrift 1=h1; Überschrift 2=h2',
                plugins: [
                  'lists',
                  'link',
                  'paste'
                ],
                paste_as_text: true,
                default_link_target: '_blank',
                toolbar:
                  'undo redo | bold italic underline | link | bullist numlist outdent indent | formatselect | removeformat'
              }},model:{value:(_vm.home_text_right),callback:function ($$v) {_vm.home_text_right=$$v},expression:"home_text_right"}}):_c('v-card-text',[_c('span',{class:{
                  barrierefrei: _vm.$route.query.barrierefrei
                },domProps:{"innerHTML":_vm._s(_vm.home_text_right)}})])],1)],1)]}}])})],1)],1),_c('div',{staticStyle:{"width":"5px","height":"150px"}}),(_vm.admin)?_c('TheTextbausteinConfirm'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }