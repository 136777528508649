import Feature from 'ol/Feature';
import WKT from 'ol/format/WKT';
import JSONFeatureFormat from 'ol/format/JSONFeature';
import {get} from 'ol/proj';
import {Point, GeometryCollection} from 'ol/geom';


class ObjektFormat extends JSONFeatureFormat {
  static wkt = new WKT();

  /**
   * @param {*} object
   * @param {import("ol/format/Feature.js").ReadOptions=} options
   * @returns {import("ol/Feature.js").default}
   */
  readFeatureFromObject(object, options) {
    const geometries = [];
    if (object.geo_poly) {
      geometries.push(ObjektFormat.wkt.readGeometry(object.geo_poly, options));
    }
    if (object.geo_lon && object.geo_lat) {
      geometries.push(new Point([object.geo_lon, object.geo_lat]).transform(options.dataProjection, options.featureProjection));
    }
    const geometry = geometries.length === 0 ? null : new GeometryCollection(geometries);
    const feature = new Feature({...object, geometry});
    feature.setId(object.id);
    return feature;
  }

  /**
   * @param {*} object
   * @param {import("ol/format/Feature.js").ReadOptions=} options
   * @returns {Array<import("ol/Feature.js").default>}
   */
  readFeaturesFromObject(object, options) {
    const length = object.length;
    const features = new Array(length);
    for (let i = 0; i < length; i++) {
      features[i] = this.readFeatureFromObject(object[i], options);
    }
    return features;
  }

  /**
   * @param {*} object
   * @return {import("ol/proj/Projection.js").default} Projection
   */
  readProjectionFromObject(object) {
    return get('EPSG:4326');
  }
}

export default ObjektFormat;
