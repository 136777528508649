import {createXYZ} from 'ol/tilegrid';
import Map from 'ol/Map';
import View from 'ol/View';
import {createAllLayers} from './mapInstanceLayerDefinitions';
import {defaults as controlDefaults} from 'ol/control';
import {defaults as interactionDefaults} from 'ol/interaction';


/**
 * creates the map instance, to be used as single vue instance property only.
 * @returns {Map}
 */
export function createMapInstance() {
  const view = new View({
    resolutions: createXYZ({tileSize: 512, maxZoom: 23}).getResolutions(),
    padding: [0, 0, 0, 0]
  });

  const map = new Map({
    layers: createAllLayers(),
    view,
    controls: controlDefaults({
      rotate: false
    }),
    interactions: interactionDefaults({
      altShiftDragRotate: false,
      pinchRotate: false
    })
  });

  return map;
}
